import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Typography, Container } from "@mui/material";

const PrivacyPolicy = () => {
  const privacyPolicyText = `
  Effective Date: 04.04.23

  Introduction
  
  Absolute-scenes.com respects your privacy and is committed to protecting it through our compliance with this privacy policy ("Privacy Policy"). This Privacy Policy describes the types of information we may collect from you or that you may provide when you use our website or services (collectively, "Services") and our practices for collecting, using, maintaining, protecting, and disclosing that information.
  
  
  What Information We Collect and How We Use It
  
  We may collect and use the following types of information:
  
  - Personal information: We may collect personal information from you, such as your name, email address, phone number, or payment information, when you use our Services. We use this information to provide our Services to you, to communicate with you about your account, and to process your payments.
  - Non-personal information: We may collect non-personal information about you, such as your browser type, IP address, or operating system, when you use our Services. We use this information to improve our Services, to analyze trends, to administer our Services, to track user movements around the Services, and to gather demographic information about our user base.
  - Cookies and other tracking technologies: We may use cookies, web beacons, or other tracking technologies to collect information about your use of our Services. We use this information to remember your preferences, to understand how you interact with our Services, and to provide you with more relevant advertising.
  
  
  How We Protect Your Information
  
  We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. All information you provide to us is stored on our secure servers behind firewalls.
  
  
  Disclosure of Your Information
  
  We may disclose personal information that we collect or you provide as described in this Privacy Policy:
  
  - To our subsidiaries and affiliates.
  - To contractors, service providers, and other third parties we use to support our business and who are bound by contractual obligations to keep personal information confidential and use it only for the purposes for which we disclose it to them.
  - To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by us about our users is among the assets transferred.
  We may also disclose your personal information to comply with any court order, law, or legal process, including to respond to any government or regulatory request.
  
  
  Your Rights and Choices
  
  You have certain rights with respect to your personal information, including:
  
  - The right to access your personal information.
  - The right to correct any inaccuracies in your personal information.
  - The right to request that we delete your personal information.
  - The right to object to the processing of your personal information for certain purposes.
  - The right to withdraw your consent to the processing of your personal information.
  
  
  Changes to Our Privacy Policy
  
  We may revise this Privacy Policy from time to time in our sole discretion. If we make any material changes to this Privacy Policy, we will notify you by posting the new Privacy Policy on this page and updating the "Effective Date" above.
  
  
  Contact Information
  
  If you have any questions or concerns about this Privacy Policy, please contact us at hello@absolute-scenes.com.
  `;

  return (
    <Container maxWidth="md">
      <Typography variant="h3" align="center" gutterBottom sx={{p:10 }}>
        Privacy Policy
      </Typography>
      <Typography variant="body1" gutterBottom style={{ whiteSpace: 'pre-wrap' }}>
        {privacyPolicyText}
      </Typography>
      <Typography variant="body2" sx={{p:10 }}>
        For more information, please see our{' '}
        <Link to="/terms-of-service">Terms of Service</Link> and{' '}
        <Link to="/cookie-policy">Cookie Policy</Link>.
      </Typography>
    </Container>
  );
};

const TermsOfService = () => {
  const termsOfServiceText = `
Effective Date: 04.04.23


Introduction

Absolute-Scenes.com provides a website and services (collectively, "Services") that enable users to create and share 3D scenes from templates. These terms of service ("Terms of Service") govern your use of our Services.


Acceptance of Terms

By accessing or using our Services, you agree to be bound by these Terms of Service and our Privacy Policy. If you do not agree to these Terms of Service or our Privacy Policy, you should not use our Services.


User Obligations

You agree to use our Services only for lawful purposes and in accordance with these Terms of Service. You agree not to use our Services:

- In any way that violates any applicable laws or regulations in the United Kingdom or in any other country where you access or use our Services, including, without limitation, any laws regarding the export of data or software to and from the United Kingdom or other countries.
- To impersonate or attempt to impersonate Absolute-Scenes.com, a Absolute-Scenes.com employee, another user, or any other person or entity.
- To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of our Services, or which, as determined by us, may harm Absolute-Scenes.com or users of our Services, or expose them to liability.


User Content

You retain all rights in, and are solely responsible for, the content you create or upload to our Services ("User Content"). By creating or uploading User Content, you grant us a non-exclusive, royalty-free, worldwide, perpetual, irrevocable, transferable, and sublicensable right to use, copy, modify, create derivative works based on, distribute, publicly display, publicly perform, and otherwise exploit in any manner such User Content in all formats and distribution channels now known or hereafter devised (including in connection with our Services and our business and on third-party sites and services), without further notice to or consent from you, and without the requirement of payment to you or any other person or entity.


Termination

We reserve the right to terminate or suspend your access to all or part of our Services, with or without notice, for any reason or no reason, including without limitation breach of these Terms of Service. If you wish to terminate these Terms of Service, you may simply discontinue using our Services.


Limitation of Liability

IN NO EVENT SHALL ABSOLUTE-SCENES.COM, ITS OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS, BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES WHATSOEVER RESULTING FROM YOUR USE OF OUR SERVICES, INCLUDING WITHOUT LIMITATION DAMAGES FOR LOSS OF USE, DATA, OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THESE TERMS OF SERVICE, WHETHER BASED ON CONTRACT, TORT, STRICT LIABILITY, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT ABSOLUTE-SCENES.COM HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.


Governing Law and Dispute Resolution

These Terms of Service shall be governed by and construed in accordance with the laws of the United Kingdom, without regard to its conflict of law provisions. Any dispute arising out of or relating to these Terms of Service shall be resolved through binding arbitration in the UK, in accordance with the rules of a reputable arbitration organisation selected by [Absolute-Scenes.com](http://absolute-scenes.com/). The prevailing party in any arbitration or litigation arising out of or relating to these Terms of Service shall be entitled to recover its reasonable attorneys' fees and costs.


Changes to Our Terms of Service

We may revise these Terms of Service from time to time in our sole discretion. If we make any material changes to these Terms of Service, we will notify you by posting the new Terms of Service on this page and updating the "Effective Date" above.


Entire Agreement

These Terms of Service, together with our Privacy Policy, constitute the entire agreement between you and Absolute-Scenes.com](http://absolute-scenes.com/) with respect to your use of our Services, and supersede all prior or contemporaneous communications and proposals, whether oral or written, between you and [Absolute-Scenes.com](http://absolute-scenes.com/) with respect to such use.

Contact Information

If you have any questions or concerns about these Terms of Service, please contact us at hello@absolute-scenes.com.
  `;
  return (
    <Container maxWidth="md">
      <Typography variant="h3" align="center" gutterBottom sx={{p:10 }}>
        Terms of Service
      </Typography>
      <Typography variant="body1" gutterBottom style={{ whiteSpace: 'pre-wrap' }}>
        {termsOfServiceText}
      </Typography>
      <Typography variant="body2" sx={{p:10 }}>
        For more information, please see our{" "}
        <Link to="/privacy-policy">Privacy Policy</Link> and{" "}
        <Link to="/cookie-policy">Cookie Policy</Link>.
      </Typography>
    </Container>
  );
};

const CookiePolicy = () => {
  const cookiePolicyText = `
Effective Date: 04.04.23


Introduction

Absolute-scenes.com uses cookies and other tracking technologies on our website and services (collectively, "Services") to improve user experience and to analyse website traffic. This Cookie Policy explains how we use cookies and other tracking technologies and your choices regarding their use.


What Are Cookies?

A cookie is a small text file that a website stores on your device when you visit the site. Cookies are used to remember user preferences, enable certain functionality, and analyze website traffic.


Types of Cookies We Use

We use the following types of cookies on our website:

- Essential cookies: These cookies are necessary for the website to function properly and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms.
- Analytical/performance cookies: These cookies allow us to recognise and count the number of visitors and to see how visitors move around our website when they are using it. This helps us to improve the way our website works, for example, by ensuring that users are finding what they are looking for easily.
- Functionality cookies: These cookies enable the website to provide enhanced functionality and personalisation. They may be set by us or by third-party providers whose services we have added to our pages.
- Targeting cookies: These cookies may be set through our site by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant adverts on other sites.


How We Use Cookies

We use cookies to:

- Remember your preferences and settings.
- Personalise your experience on our website.
- Analyse website traffic and user behaviour.
- Deliver targeted advertising.


Your Choices

You can control or disable cookies through your web browser settings. However, please note that disabling cookies may affect your ability to access certain parts of our website or use certain features of our Services.

If you do not wish to accept cookies from our website, you can configure your browser to refuse cookies. You can also delete any existing cookies on your device by clearing your browser history and cache.


Updates to Our Cookie Policy

We may update this Cookie Policy from time to time in our sole discretion. If we make any material changes to this Cookie Policy, we will notify you by posting the new Cookie Policy on this page and updating the "Effective Date" above.


Contact Information

If you have any questions or concerns about this Cookie Policy, please contact us at hello@absolute-scenes.com.
  `;
  return (
    <Container maxWidth="md">
      <Typography variant="h3" align="center" gutterBottom sx={{p:10 }}>
        Cookie Policy
      </Typography>
      <Typography variant="body1" gutterBottom style={{ whiteSpace: 'pre-wrap' }}>
        {cookiePolicyText}
      </Typography>
      <Typography variant="body2" sx={{p:10 }}>
        For more information, please see our{" "}
        <Link to="/privacy-policy">Privacy Policy</Link> and{" "}
        <Link to="/terms-of-service">Terms of Service</Link>.
      </Typography>
    </Container>
  );
};

export { PrivacyPolicy, TermsOfService, CookiePolicy };