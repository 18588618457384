import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Box, Card, Typography, Stack, Grid, IconButton, Menu, MenuItem, Button, Container } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';

const StyledProductImg = styled("img")({
  top: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "absolute",
  border: "5px solid white",
  borderRadius: "16px",
});

export default function TemplateListPB() {
  const [templates, setTemplates] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [noTemplatesFound, setNoTemplatesFound] = useState(false);

  useEffect(() => {
    const jwtToken = localStorage.getItem("jwtToken");
    axios
      .get("https://api.absolute-scenes.com/user/templates", {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      })
      .then((response) => {
        console.log(response.data);

        if (response.data.data === null) {
          setNoTemplatesFound(true);
        } else {
          setTemplates(response.data.data);
        }
      })
      
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleClick = (event, template) => {
    setAnchorEl(event.currentTarget);
    setSelectedTemplate(template);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedTemplate(null);
  };

  const handleDelete = (templateId) => {
    const jwtToken = localStorage.getItem("jwtToken");
    axios
      .post(`https://api.absolute-scenes.com/template/delete`, { id: templateId }, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      })
      .then((response) => {
        const updatedTemplates = templates.filter((template) => template.id !== templateId);
        setTemplates(updatedTemplates);
        handleClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
    <Container>
      {noTemplatesFound && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h3" sx={{ mb: 3 }}>
          Start creating your first scene!

          </Typography>
          <Typography sx={{ mb: 5 }}>
            Pick a template to get started
          </Typography>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/dashboard/products/"
          >
            Browse templates
          </Button>
        </Box>
      )}
      </Container>
      {!noTemplatesFound && (
        <Grid container spacing={1}>
          {templates.map((item) => (
           <Grid key={item.id} item xs={6} sm={6} md={3}>
           <Card>
           <Link
               color="inherit"
               style={{ textDecoration: "none" }}
               to={`/template/${item.scene?.ground_color || '1'}/${item.id}`}
             >
             <Box sx={{ pt: "100%", position: "relative" }}>
             <StyledProductImg
  alt={item.title}
  src={
    item.posters && item.posters.length > 0 && item.posters[0].url
      ? item.posters[0].url
      : item.scene.ground_color === '1'
      ? 'https://spestect.sirv.com/Productshowcase.jpg'
      : item.scene.ground_color === '3'
      ? 'https://spestect.sirv.com/Screenshot%202023-04-24%20at%2015.27.45.webp'
      : item.scene.ground_color === '4'
      ? 'https://spestect.sirv.com/Screenshot%202023-04-29%20at%2015.43.23.jpg'
      : 'https://spestect.sirv.com/Screenshot%202023-04-11%20at%2016.50.25.webp'
  }
/>

                  </Box>
                   </Link>
                  <IconButton
                    sx={{ position: 'absolute', bottom: 0, right: 0, color: 'black',p: 1, my:1  }}
                    aria-label="more options"
                    aria-controls="options-menu"
                    aria-haspopup="true"
                    onClick={(e) => handleClick(e, item)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  
                  <Menu
                    id="options-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl) && selectedTemplate && selectedTemplate.id === item.id}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <MenuItem onClick={() => handleDelete(item.id)}>Delete</MenuItem>
                  </Menu>
                
                <Link
                  color='inherit'
                  style={{ textDecoration: 'none',   color: 'black' }}
                  to={`/template/${item.scene?.ground_color || '1'}/${item.id}`}
                >
        <Stack spacing={1} sx={{ px: 3, py: 1 }}>
                  <Typography variant="subtitle2" sx={{ height: "36px", lineHeight: "18px", overflow: "hidden", display: "-webkit-box", WebkitLineClamp: "2", WebkitBoxOrient: "vertical" }}>
                      {item.title}
                    </Typography>
                  </Stack>
                </Link>
              </Card>
            </Grid>
             ))}
           </Grid>
         )}
       </>
       );
      }
