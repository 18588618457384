import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Stack } from '@mui/material';
import PocketBase from 'pocketbase';
import { TemplateListPB } from '../sections/@dashboard/products';


export default function DashboardAppPage() {
  const theme = useTheme();



    
  
  return (
    <>
      <Helmet>
        <title> Absolute Scenes | My Scenes </title>
      </Helmet>
  
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          My Scenes
        </Typography>
  
        
          <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
            <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
           
            </Stack>
            <TemplateListPB/>
          </Stack>
      
      </Container>
    </>
  );
  
}
