import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
// @mui

import {
  Box,
  Card,
  Typography,
  Stack,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton
} from "@mui/material";
import ShopProductCard from "./ProductCard";
import CloseIcon from '@mui/icons-material/Close';

// ----------------------------------------------------------------------

ProductList.propTypes = {
  products: PropTypes.array.isRequired,
};

const StyledProductImg = styled("img")({
  top: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "absolute",
  border: "5px solid white",
  borderRadius: "16px",
});

const BulletPoint = styled(Typography)(({ theme }) => ({
  '&::before': {
    content: '"• "',
    color: theme.palette.text.primary,
  },
  display: 'inline',
}));

export default function ProductList({ products, ...other }) {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const availableTemplates = [
    {
      name: "Product Showcase",
      templateType: "1",
      imgSrc: "https://spestect.sirv.com/Productshowcase.jpg",
      description: "This template lets you showcase 3D models in a simple but effective product display with optimized lighting. Best of all, this scene can be viewed in VR directly from the link, making it perfect for showcasing your products in a fully immersive experience.",
      bulletPoints: [
        "3D File support: glb, gltf",
        "Supports animations",
        "10Mb limit",
        "VR optimised",
        "Include Store Links/ Affiliate Links "
      ],
    },
    {
      name: "Basic T-Shirt Display",
      templateType: "2",
      imgSrc: "https://spestect.sirv.com/Screenshot%202023-04-11%20at%2016.50.25.webp",
      description: "This t-shirt template enables you to easily customize the color and design of the t-shirt to create a personalised product showcase. Simply change the t-shirt color and upload your own design to create a unique and engaging presentation. ",
      bulletPoints: [
        "Image types: .png, .jpg, .webp",
        "VR optimised",
        "2MB limit",
        "Include Buy now links/ Affiliate Links "
      ],
    },
    {
      name: "Vologram display",
      templateType: "3",
      imgSrc: "https://spestect.sirv.com/Screenshot%202023-04-24%20at%2015.27.45.webp",
      description: "X",
      bulletPoints: [
        "Image types: .png, .jpg, .webp",
        "VR optimised",
        "2MB limit",
        "Include Buy now links/ Affiliate Links "
      ],
    },
    {
      name: "Generative AI T-Shirt Designer",
      templateType: "4",
      imgSrc: "https://spestect.sirv.com/Screenshot%202023-04-29%20at%2015.43.23.jpg",
      description: "This t-shirt template enables you to easily create a design using generative AI. Simply enter a prompt and hit generate!",
      bulletPoints: [
        "Image types: .png, .jpg, .webp",
        "VR optimised",
        "Generative AI",
        
      ],
    },
  ];

  const handleOpen = (template) => {
    setSelectedTemplate(template);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleItemClick = (templateType) => {
    handleClose();
 

   
      const jwtToken = localStorage.getItem("jwtToken");
    
      axios
        .post("https://api.absolute-scenes.com/template/create", {}, {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        })
        .then((response) => {
          console.log(response.data);
    
          // Fetch the latest template to get its id
          axios
            .get("https://api.absolute-scenes.com/user/templates", {
              headers: {
                Authorization: `Bearer ${jwtToken}`,
              },
            })
            .then((response) => {
              const lastTemplate = response.data.data[response.data.data.length - 1];
              const templateId = lastTemplate.id;
    
              // Prepare the payload for the 'update' endpoint
              const payload = {
                scene: {ground_color: templateType} 
      };
    
              // Update the templateType using the 'update' endpoint
              axios
                .post(
                  `https://api.absolute-scenes.com/template/update/${templateId}`,
                  payload,
                  {
                    headers: {
                      Authorization: `Bearer ${jwtToken}`,
                      "Content-Type": "application/json",
                    },
                  }
                )
                .then((updateResponse) => {
                  // Redirect to the template page
                  window.location.href = `/template/${templateType}/${templateId}`;
                })
                .catch((error) => {
                  console.log(error);
                });
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    };
    
    

  
    return (
<Grid container spacing={1} {...other}>
  <Grid item xs={6} sm={6} md={3}>
    <Link
      color="inherit"
      style={{ textDecoration: "none" }}
      onClick={() => handleOpen(availableTemplates[0])}
    >
      <Card sx={{ height: "100%" }}>
        <Box sx={{ pt: "100%", position: "relative" }}>
          <StyledProductImg
            alt="Template img"
            src="https://spestect.sirv.com/Productshowcase.jpg"
          />
        </Box>
        <Stack spacing={1} sx={{ px: 3, py: 1 }}>
          <Typography variant="subtitle2" sx={{ height: "36px", lineHeight: "18px", overflow: "hidden", display: "-webkit-box", WebkitLineClamp: "2", WebkitBoxOrient: "vertical" }}>
            Product Showcase
          </Typography>
        </Stack>
      </Card>
    </Link>
  </Grid>

  <Grid item xs={6} sm={6} md={3}>
    <Link
      color="inherit"
      style={{ textDecoration: "none" }}
      onClick={() => handleOpen(availableTemplates[1])}
    >
      <Card sx={{ height: "100%" }}>
        <Box sx={{ pt: "100%", position: "relative" }}>
          <StyledProductImg
            alt="Template img"
            src="https://spestect.sirv.com/Screenshot%202023-04-11%20at%2016.50.25.webp"
          />
        </Box>
        <Stack spacing={1} sx={{ px: 3, py: 1 }}>
          <Typography variant="subtitle2" sx={{ height: "36px", lineHeight: "18px", overflow: "hidden", display: "-webkit-box", WebkitLineClamp: "2", WebkitBoxOrient: "vertical" }}>
            Basic T-Shirt Design  
          </Typography>
        </Stack>
      </Card>
    </Link>
  </Grid>

  <Grid item xs={6} sm={6} md={3}>
    <Link
      color="inherit"
      style={{ textDecoration: "none" }}
      onClick={() => handleOpen(availableTemplates[2])}
    >
      <Card sx={{ height: "100%" }}>
        <Box sx={{ pt: "100%", position: "relative" }}>
          <StyledProductImg
            alt="Template img"
            src="https://spestect.sirv.com/Screenshot%202023-04-24%20at%2015.27.45.webp"
          />
        </Box>
        <Stack spacing={1} sx={{ px: 3, py: 1 }}>
          <Typography variant="subtitle2" sx={{ height: "36px", lineHeight: "18px", overflow: "hidden", display: "-webkit-box", WebkitLineClamp: "2", WebkitBoxOrient: "vertical" }}>
            Vologram display 
          </Typography>
        </Stack>
      </Card>
    </Link>
  </Grid>

  <Grid item xs={6} sm={6} md={3}>
    <Link
      color="inherit"
      style={{ textDecoration: "none" }}
      onClick={() => handleOpen(availableTemplates[3])}
    >
      <Card sx={{ height: "100%" }}>
        <Box sx={{ pt: "100%", position: "relative" }}>
          <StyledProductImg
            alt="Template img"
            src="https://spestect.sirv.com/Screenshot%202023-04-29%20at%2015.43.23.jpg"
          />
        </Box>
        <Stack spacing={1} sx={{ px: 3, py: 1 }}>
          <Typography variant="subtitle2" sx={{ height: "36px", lineHeight: "18px", overflow: "hidden", display: "-webkit-box", WebkitLineClamp: "2", WebkitBoxOrient: "vertical" }}>
            AI T-Shirt Generator  
          </Typography>
        </Stack>
      </Card>
    </Link>
  </Grid>




        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
  <DialogTitle>
    <IconButton
      edge="end"
      color="inherit"
      onClick={handleClose}
      aria-label="close"
      sx={{
        position: 'absolute',
        right: '12px', // Decrease this value to move the IconButton to the right
        top: '8px',
        color: (theme) => theme.palette.grey[500],
      }}
    >
      <CloseIcon />
    </IconButton>
  </DialogTitle>
  <DialogContent>
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
      <Box sx={{ flexGrow: 1, maxWidth: '100%', paddingTop: '100%', position: 'relative' }}>
  <img
    src={selectedTemplate ? selectedTemplate.imgSrc : ""}
    alt="Template Preview"
    style={{
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      position: 'absolute',
      borderRadius: '8px',
      top: 0,
      left: 0
    }}
  />
</Box>

      </Grid>
      <Grid item xs={12} md={6}>
        <Box sx={{ pl: 2 }}>
          <Typography variant="h4" gutterBottom sx={{mb:3}}>
            {selectedTemplate ? selectedTemplate.name : ""}
          </Typography>
          {selectedTemplate &&
            selectedTemplate.description.split("\n").map((line, index) => (
              <Typography key={index} sx={{mb:3}} gutterBottom>
                {line}
              </Typography>
            ))}
          {selectedTemplate &&
            selectedTemplate.bulletPoints.map((point, index) => (
              <React.Fragment key={index}>
                <BulletPoint>{point}</BulletPoint>
                <br />
              </React.Fragment>
            ))}
        </Box>
      </Grid>
    </Grid>
  </DialogContent>
  <DialogActions>
    <Button
      variant="contained"
      onClick={() => handleItemClick(selectedTemplate.templateType)}
      color="primary"
      sx={{
        right: '10px', // Decrease this value to move the IconButton to the right
        bottom: '10px'
      }}
    >
      Use this Template
    </Button>
  </DialogActions>
</Dialog>
</Grid>
  );
}