import React, { useRef } from 'react'
import { useGLTF, Decal, useTexture } from '@react-three/drei'
import { folder, useControls, LevaPanel, Leva, button } from 'leva'
import * as THREE from 'three'

export function Model(props) {
  const { TDesign, TColor } = useControls({
    Tshirt: folder({
      TDesign: { image: 'https://spestect.sirv.com/pexels-nati-13610999.jpg' },
      TColor: { value: '#f8f8f8', label: 'Color' }
    })
  })

  const { nodes, materials } = useGLTF('/tshirt2.glb')
  const [design] = useTexture([TDesign ? TDesign : 'https://spestect.sirv.com/Screenshot%202023-02-10%20at%2010.01.29.png'])
  materials.Sleeves_FRONT_2669.opacity = 1
  return (
    <group {...props} dispose={null}>
      <group rotation={[-1.6608651, 0.0028782, -0.5035308]}>
        <group rotation={[Math.PI / 2, 0, 0]}>
          <mesh castShadow receiveShadow geometry={nodes.Object_16.geometry} material={materials.Body_FRONT_2664.color.set(new THREE.Color(TColor))} />
          <mesh castShadow receiveShadow geometry={nodes.Object_14.geometry} material={materials.Body_FRONT_2664} />
          <mesh castShadow receiveShadow geometry={nodes.Object_15.geometry} material={materials.Body_FRONT_2664} />
          <mesh castShadow receiveShadow geometry={nodes.Object_11.geometry} material={materials.Body_FRONT_2664} />
          <mesh castShadow receiveShadow geometry={nodes.Object_10.geometry} material={materials.Body_FRONT_2664}>
            <Decal position={[0, 1.3, 0]} rotation={[0, 0, 0]} scale={[0.2, 0.3, 0.3]} map={design} roughness={1} map-anisotropy={16} />
          </mesh>
          <mesh castShadow receiveShadow geometry={nodes.Object_12.geometry} material={materials.Body_FRONT_2664} />
          <mesh castShadow receiveShadow geometry={nodes.Object_6.geometry} material={materials.Body_FRONT_2664} />
          <mesh castShadow receiveShadow geometry={nodes.Object_8.geometry} material={materials.Body_FRONT_2664} />
          <mesh castShadow receiveShadow geometry={nodes.Object_18.geometry} material={materials.Sleeves_FRONT_2669}>
            <meshStandardMaterial attach="material" color={TColor} />
          </mesh>
          <mesh castShadow receiveShadow geometry={nodes.Object_20.geometry} material={materials.Sleeves_FRONT_2669}>
            <meshStandardMaterial attach="material" color={TColor} />
          </mesh>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/tshirt2.glb')
