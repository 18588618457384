import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

// @mui
import { Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

export default function ResetPasswordForm(props) {
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [errors, setErrors] = useState({});
  const { token } = useParams();

  const validateForm = () => {
    let errors = {};
  
    if (!password) {
      errors.password = 'Password is required';
    } else if (password.length < 8) {
      errors.password = 'Password must be at least 8 characters long';
    } else if (!/\d/.test(password)) {
      errors.password = 'Password must contain at least one number';
    } else if (!/[a-zA-Z]/.test(password)) {
      errors.password = 'Password must contain at least one letter';
    }
  
    if (!confirmPassword) {
      errors.confirmPassword = 'Please confirm your password';
    } else if (password !== confirmPassword) {
      errors.confirmPassword = 'Passwords do not match';
    }
  
    setErrors(errors);

    return Object.keys(errors).length === 0; // return true if there are no errors
  };

  const handleClick = async () => {
    if (!validateForm()) {
      return;
    }
  
    try {
      const response = await axios.post('https://api.absolute-scenes.com/reset/password', {
        token,
        password,
      });
  
      if (response.status === 200) {
        // navigate to the login page
        navigate('/login');
      } else {
        setError('Error resetting password');
      }
    } catch (error) {
      console.error(error);
      setError('Error resetting password');
    }
  };
  

  return (
    <>
      <Stack spacing={3}>
        <TextField
          name="password"
          label="New Password"
          type="password"
          error={errors.password}
          helperText={errors.password}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        <TextField
          name="confirmPassword"
          label="Confirm New Password"
          type="password"
          error={errors.confirmPassword}
          helperText={errors.confirmPassword}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick} sx={{ mt: 2, mb: 2 }}>
        Reset Password
      </LoadingButton>
      
      {error && (
        <Typography variant="body2" sx={{ mt: 1, mb: 2 }} color="error">
          {error}
        </Typography>
      )}
    </>
  );
}
