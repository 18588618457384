import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // import axios library
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

export default function SignupForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const [name, setName] = useState(''); // add state for name
  const [email, setEmail] = useState(''); // add state for email
  const [password, setPassword] = useState(''); // add state for password
  const [isLoading, setIsLoading] = useState(false); // add state for loading indicator
  const [errors, setErrors] = useState({}); // add state for validation errors
  const [serverError, setServerError] = useState('');

  const validateForm = () => {
    let errors = {};
  
    if (!name) {
      errors.name = 'Name is required';
    }
  
    if (!email) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Email is invalid';
    }
  
    if (!password) {
      errors.password = 'Password is required';
    } else if (password.length < 8) {
      errors.password = 'Password must be at least 8 characters long';
    } else if (!/\d/.test(password)) {
      errors.password = 'Password must contain at least one number';
    } else if (!/[a-zA-Z]/.test(password)) {
      errors.password = 'Password must contain at least one letter';
    }
  
    setErrors(errors);
  
    return Object.keys(errors).length === 0; // return true if there are no errors
  };
  
  

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.post('https://api.absolute-scenes.com/register', { // send a POST request to the backend API
        name,
        email,
        password,
      });
      if (response.status === 200) {
        const token = response.data.token;
        localStorage.setItem('jwtToken', token);
        setIsLoading(false);
        navigate('/dashboard', { replace: true });
      } else {
        setIsLoading(false);
        console.log('Invalid email or password');
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      if (error.response && error.response.data.message === 'service_register_user_exist: user already exists') {
        setServerError('Email is already registered');
      } else {
        setServerError('Something went wrong. Please try again later.');
      }
      console.log('Something went wrong. Please try again later.');
    }
  };

  return (
    <form onSubmit={handleSubmit}> {/* add onSubmit handler */}
      <Stack spacing={3}>
        <TextField
          name="name"
          label="Name"
          error={errors.name}
          helperText={errors.name}
          onChange={(e) => setName(e.target.value)}
          value={name}
        />
        <TextField
          name="email"
          label="Email address"
          error={errors.email || serverError}
          helperText={errors.email || serverError}
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          error={errors.password}
          helperText={errors.password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={(e) => setPassword(e.target.value)}
          value={password}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{my: 2 }}>
{/* add any additional UI components as needed */}
</Stack>
<LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isLoading}>
    Sign up
  </LoadingButton>
</form>
);
}