import * as THREE from 'three'
import { useEffect, useLayoutEffect, Suspense, useState, useRef } from 'react'
import { Canvas, applyProps, useFrame, AnimationMixer } from '@react-three/fiber'
import {
  useVideoTexture,
  useTexture,
  Sphere,
  Cylinder,
  Center,
  AccumulativeShadows,
  RandomizedLight,
  OrbitControls,
  Environment,
  useGLTF,
  TorusKnot,
  Image,
  Float,
  ContactShadows,
  Decal,
  PerspectiveCamera
} from '@react-three/drei'
import { FlakesTexture } from 'three-stdlib'
import { VRButton, XR, Controllers } from '@react-three/xr'
import { folder, useControls, LevaPanel, Leva, button } from 'leva'

import { Model } from '../sections/home/Model';
import './styles.css';
import { Link } from 'react-router-dom';
import { Box, Button } from '@mui/material';

export default function Scene2() {
  const { Background, Image2 } = useControls({
    Background: { value: '#ffebaf', label: 'Background' },
    Image2: { image: 'https://spestect.sirv.com/pexels-nati-13610999.jpg', label: 'Poster' }
  })

  const { TDesign, TColor } = useControls({
    Tshirt: folder({
      TDesign: { image: 'https://spestect.sirv.com/pexels-nati-13610999.jpg' },
      TColor: { value: '#f8f8f8', label: 'Color' }
    })
  })


  const { Name, Price, URL, Buy, Fontcolor } = useControls({
    Details: folder({
      Name: { value: 'Product name', label: 'Name' },
      Price: { value: '£X.XX', label: 'Price' },
      Buy: { value: 'BUY NOW', label: 'Link copy' },
      URL: { value: 'https://shopify.com', label: 'Link URL ' },
      Fontcolor: { value: '#000000', label: 'Font Color' }
    })
  })

  return (
    <>
    <Box sx={{my:0, mx: { xs: 2, sm: 0, md: 2 }, pt: 2, zIndex: 1, position: 'fixed', bottom: '2rem', right: '2rem' }}>
    <Link to="/signup" style={{ textDecoration: 'none', display: 'inline-flex' }}>
      <Button
        size="large"
        type="submit"
        color="secondary"
        variant="contained"
        sx={{
          whiteSpace: 'nowrap',
          borderRadius: '50px', // set borderRadius to 50px for a circular shape
          px: '1.5rem', // add horizontal padding
          py: '1rem', // add vertical padding
          '& .MuiButton-label': {
            textTransform: 'capitalize',
            fontWeight: 'bold',
            fontSize: '1.2rem' // set font size to 1.2rem
          }
        }}
      >
        Sign up for free
      </Button>
    </Link>
  </Box>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'black',
          color: 'white',
          margin: 0,
          padding: 0
        }}>
              <>
      <div style={{ color: Fontcolor, position: 'absolute', bottom: 0, left: 0, fontSize: '18px', margin: 60, zIndex: 999 }}>
        {Name}
        <br />
        <div style={{ fontWeight: 'bold' }}>{Price}</div>
        <br />

        <div href={URL} style={{ position: 'relative', marginTop: 10, display: 'inline-block' }}>
          <a style={{ color: Fontcolor, fontSize: '15px', fontWeight: 600, letterSpacing: 2, textDecoration: 'none' }} href={URL}>
            {Buy}
          </a>
          <div style={{ marginTop: 6, height: 2.5, width: '100%', background: Fontcolor }} />
        </div>
        <br />
      </div>
    </>
          <Leva />
          <Canvas class="canvas" shadows camera={{ position: [0, 1.5, 10], fov: 25 }} style={{ background: [Background],  }} >
          <XR>
          <OrbitControls autoRotate={true} autoRotateSpeed={0} minPolarAngle={0} maxPolarAngle={Math.PI / 2} maxDistance={8} target={[0, 1, 0]} />
          <mesh castShadow position={[0, 1.5, -2]} scale={1}>
            <Image scale={[1.3, 2.2]} url={Image2 ? Image2 : 'https://spestect.sirv.com/Screenshot%202023-02-10%20at%2010.01.29.png'} />
          </mesh>

            <group scale={1} position={[0, 0, 0]}></group>

            <Sphere castShadow receiveShadow args={[0.05, 50, 50]} position={[1.5, 0.5, 0]}>
              <meshStandardMaterial color={Background} />
            </Sphere>
            <Sphere castShadow receiveShadow args={[0.07, 50, 50]} position={[1.3, 0.7, 0.3]}>
              <meshStandardMaterial color={Background} />
            </Sphere>
            <Sphere castShadow receiveShadow args={[0.05, 50, 50]} position={[1.4, 0.5, -0.3]}>
              <meshStandardMaterial color={Background} />
            </Sphere>
            <Sphere castShadow receiveShadow args={[0.07, 50, 50]} position={[-1.5, 0.7, 0]}>
              <meshStandardMaterial color={Background} />
            </Sphere>
            <Sphere castShadow receiveShadow args={[0.03, 50, 50]} position={[-1.2, 0.7, 0.3]}>
              <meshStandardMaterial color={Background} />
            </Sphere>
            <Sphere castShadow receiveShadow args={[0.05, 50, 50]} position={[-1.4, 0.5, -0.3]}>
              <meshStandardMaterial color={Background} />
            </Sphere>

            <group>
              <spotLight intensity={0.5} args={['#ffffff', 1.5, 100, 20, 1]} rotation={[0, 0, 0]} position={[0, 2, 0]} castShadow />
            </group>
            <Float>
              <Model position={[0, -0.28, 0]} />
            </Float>
            
            <Environment preset="warehouse" />
            <group scale={[1, 1, 1]} position={[0, 1.6, -0]} rotation={[0, 0, 0]}>
              <Wall Background={Background} />
            </group>
          </XR>
        </Canvas>
      </div>
    </>
  )
}



function Wall(props) {
  const { scene, materials } = useGLTF('/bgscene2.glb')
  useLayoutEffect(() => {
    scene.traverse((obj) => obj.isMesh && (obj.receiveShadow = obj.castShadow = true))
    applyProps(materials.Material, {
      color: props.Background
    })
  })
  return <primitive object={scene} {...props} rotation={[0, Math.PI / 2, 0]} position={[0, 0, 0]} />
}

