import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { alpha, styled } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover, Link } from '@mui/material';
import React, { useContext } from 'react';
import UserContext from '../../../contexts/UserContext';

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: 'eva:home-fill',
  },
  {
    label: 'Profile',
    icon: 'eva:person-fill',
  },
  {
    label: 'Settings',
    icon: 'eva:settings-2-fill',
  },
];

export default function AccountPopover() {
  const account = useContext(UserContext);
  const [open, setOpen] = useState(null);
  const navigate = useNavigate();

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = () => {
    handleClose();
    localStorage.removeItem('jwtToken');
    localStorage.clear();
    navigate('/login');
  };

  const pointer = {cursor: 'pointer'};

  return (
    <>
<Box sx={{ mb: 5, mx: 2.5 }} onClick={handleOpen} style={pointer}>
  <Link underline="none">
    <StyledAccount>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        {account && <Avatar src={account.photoURL} alt="photoURL" />}
      </IconButton>

      <Box sx={{ ml: 2 }}>
        {account ? (
          <>
            <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
              {account.displayName}
            </Typography>

         
          </>
        ) : (
          <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
            Please log in
          </Typography>
        )}
      </Box>
    </StyledAccount>
  </Link>
</Box>


      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
      

       

     

   

    <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
      Sign out
    </MenuItem>
  </Popover>
</>
);
}