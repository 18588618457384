import { useParams } from 'react-router-dom';
import Scene2 from './TemplateEditor3';
import TemplateEditor4 from './TemplateEditor4';
import TemplateEditor5 from './TemplateEditor5';
import TemplateEditor6 from './TemplateEditor6';

function SceneWrapper() {
    const { templateType, id } = useParams();
  
    // Fetch the template data from the backend using the `id` parameter
    // Apply any customizations to the template component
    // Get the `templateType` from the `linkcopy` field in the `details` object
  
    switch (templateType) {
      case '1':
        return <Scene2 id={id} />;
      case '2':
        return <TemplateEditor4 id={id} />;
        case '3':
        return <TemplateEditor5 id={id} />;
        case '4':
          return <TemplateEditor6 id={id} />;
      default:
        // Handle unknown template types, e.g., show an error message
        return <div>Unknown template type</div>;
    }
  }
  
  export default SceneWrapper;
