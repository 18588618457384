import { Helmet } from 'react-helmet-async';
import { Link } from "react-router-dom";
// @mui
import { styled } from '@mui/material/styles';
import zIndex from '@mui/material/styles/zIndex';
import { Container, Typography, Divider, Stack, Button,Box, useMediaQuery } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
import Iconify from '../components/iconify';
// sections
import { ResetPasswordForm } from '../sections/auth/login';



// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
  background: `url('/assets/signin_bg.jpg') no-repeat center `,
  backgroundSize: 'cover'
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function ResetPassword() {
  const mdUp = useResponsive('up', 'md');
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <>
      <Helmet>
        <title> Reset Password | Absolute Scenes </title>
      </Helmet>

      <StyledRoot>
   

        {mdUp && (
          <StyledSection>
                        <Box
      component={Link}
      to="/home"
       sx={{ width: 160, height: 'auto', cursor: 'pointer', position: 'fixed',
       top: { xs: 16, sm: 24, md: 40 },
       left: { xs: 16, sm: 24, md: 40 }}}
    >
      <img src="/assets/absolutescenes.png" alt="Logo" />
      </Box>
       
            
           
            
          </StyledSection>
        )}
{isMobile && (
      <Box
        component={Link}
        to="/home"
        sx={{
          width: 160,
          height: 'auto',
          cursor: 'pointer',
          position: 'fixed',
          top: { xs: 16, sm: 24, md: 40 },
          left: { xs: 16, sm: 24, md: 40 },
        }}
      >
        <img src="/assets/absolutescenesblack.svg" alt="Logo" />
      </Box>
    )}
        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom sx={{}}>
            Reset your password
            </Typography>
            <Typography variant="body2" sx={{ mb: 5 }}>
              Enter your new password.
            </Typography>
           

        

            

            <ResetPasswordForm/>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
