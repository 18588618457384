import * as React from 'react'
import { Helmet } from 'react-helmet-async';
import { useInView } from 'react-intersection-observer'
import { Suspense } from 'react'
import { Loader } from '@react-three/drei'
import { useSpring, animated } from 'react-spring'
import { styled } from '@mui/material/styles'
import { Link } from "react-router-dom";
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import MenuIcon from '@mui/icons-material/Menu'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faLinkedin, faTiktok } from '@fortawesome/free-brands-svg-icons';

import { Link as Scroll } from 'react-scroll'

import TemplateEditor from './TemplateEditor2';

const AnimatedBox = animated(Box)

const drawerWidth = 240
const navItems = []

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}))

export default function Home(props) {
  const { window } = props
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState)
  }

  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'left' }}>
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <img src="/assets/absolutescenesblack.svg" style={{ width: '150px' }} />
      </Box>

      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
        <Box sx={{ px: 2.5, py: 3, marginLeft: 'auto', display: 'flex', flexDirection: 'column' }}>
        
          <Button size="large" type="submit" color="inherit" variant="text" sx={{ mr: 1, width: '100%', mb: 2 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', textTransform: 'capitalize' }}>
              Sign in
            </Typography>
          </Button>
         
          <Button size="large" type="submit" color="secondary" variant="contained" sx={{ width: '100%' }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', textTransform: 'capitalize' }}>
              Sign up
            </Typography>{' '}
          </Button>
         
        </Box>
      </List>
    </Box>
  )

  const container = window !== undefined ? () => window().document.body : undefined

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1200,
    height: 800,
    maxHeight: '90vw',
    maxWidth: '90vw', // Add a maxWidth value
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 0,
    p: 0
  }

  const { ref, inView } = useInView({ threshold: 0.1, triggerOnce: true })
  const fadeAndMoveUp = useSpring({
    from: { opacity: 0, transform: 'translateY(50px)' },
    to: { opacity: inView ? 1 : 0, transform: inView ? 'translateY(0px)' : 'translateY(50px)' },
    immediate: false
  })

  const fadeAndMoveUp2 = useSpring({
    from: { opacity: 0, transform: 'translateY(50px)' },
    to: { opacity: 1, transform: 'translateY(0px)' }
  })

  const leftImageProps = useSpring({
    from: { transform: 'translateX(-50%)' },
    to: { transform: 'translateX(50%)' },
    loop: true,
    config: { duration: 50000 }
  })

  const rightImageProps = useSpring({
    from: { transform: 'translateX(50%)' },
    to: { transform: 'translateX(-50%)' },
    loop: true,
    config: { duration: 50000 }
  })

  const StyledProductImg = styled('img')({
    top: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute'
  })

  return (
    
    <Box sx={{ background: '#A5E0FC', m: 0 }}>
      <Helmet>
        <title>Absolute Scenes </title>
      </Helmet>
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', maxWidth: '1200px', margin: 'auto' }}>
            <TemplateEditor/>
            <AnimatedBox style={{ height: '100vh', marginTop: '0px', marginBottom:'0px' }}>
        <CssBaseline />
        <div style={{ width:'100%', padding: '0 8px' }}>
                  <AppBar
        position="relative"
        sx={{
        backgroundColor: 'white',
        borderRadius: '50px',
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)', // add boxShadow
        margin: '16px 24px', // add padding
        '& .MuiToolbar-root': {
        paddingLeft: '0',
        paddingRight: '0',
        },
        height: '80px', 
        zIndex: (theme) => theme.zIndex.drawer + 1, // set zIndex to a higher value
        mx: { xs: 'auto', sm: 'auto' }, // add margin on mobile
  }}
>

          <Toolbar>
          <IconButton
  color="inherit"
  aria-label="open drawer"
  edge="start"
  onClick={handleDrawerToggle}
  sx={{ ml: 2, display: { sm: 'none' }, color: 'black' }}
>
  <MenuIcon />
</IconButton>


            <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>
              <Box sx={{ px: 2.5, py: 3, display: 'inline-flex', pl: 5 }}>
                <img src='/assets/absolutescenesblack.svg' style={{ width: '200px' }} />
                <Typography variant="subtitle1" sx={{ color: 'black', backgroundColor: '#FBC864', borderRadius: '5px', marginLeft: '1rem', padding: '0.25rem 0.5rem', fontSize: '0.9rem'  }}>Beta</Typography>

              </Box>
            </Typography>

            <Box sx={{ px: 2.5, py: 3, display: 'inline-flex', marginLeft: 'auto' }}>
            <Link to="/login" style={{ textDecoration: 'none', width: '100%' }}>
              <Button size="large" type="submit"  variant="text" sx={{ mr: 1 }}>
                <Typography variant="subtitle1" sx={{ color:"black", fontWeight: 'bold', textTransform: 'capitalize' }}>
                  Sign in
                </Typography>
              </Button>
              </Link>
              <Link to="/signup" style={{ textDecoration: 'none', width: '100%' }}>
              <Button
  size="large"
  type="submit"
  color="secondary"
  variant="contained"
  sx={{
    mr: 1,
    whiteSpace: 'nowrap',
    borderRadius: '50px', // set borderRadius to 50px for a circular shape
  }}
>
  <Typography variant="subtitle1" sx={{ fontWeight: 'bold', textTransform: 'capitalize' }}>
    Sign up
  </Typography>
</Button>

              </Link>
            </Box>
          </Toolbar>
        </AppBar>
        </div>
        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidth
              }
            }}>
            {drawer}
          </Drawer>
        </Box>
       
       
       <Box component="main" sx={{ m: 0, width: '50%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', '@media (max-width: 600px)': { width: '100%' } }}>
  <Toolbar />
  <Box sx={{ mx: { xs: 2, sm: 2, md: 2 }, pt: 0, mt: 0, zIndex: 1 }}>
  <Typography align="left" color="white" gutterBottom sx={{ pt: 0, mt: 0, fontSize: {xs: '3rem', sm:'4rem', md: '5rem'}, fontFamily: 'Archivo Black', lineHeight: 1 }}>
  Create immersive 3D content in seconds
</Typography>



    <Typography variant="subtitle1" align="left" gutterBottom color="white" sx={{ fontWeight:'regular', fontSize: {xs: '1rem', sm:'1rem', md: '1rem'}, my:4   }}>
    Drive sales and elevate your e-commerce presence with eye-catching 3D experiences, effortlessly shared through 3D links.
    </Typography>
  </Box>
  <Box sx={{my:0, mx: { xs: 2, sm: 0, md: 2 }, pt: 2, zIndex: 1 }}>
    <Link to="/signup" style={{ textDecoration: 'none', display: 'inline-flex' }}>
      <Button
        size="large"
        type="submit"
        color="secondary"
        variant="contained"
        sx={{
          whiteSpace: 'nowrap',
          borderRadius: '50px', // set borderRadius to 50px for a circular shape
          px: '1.5rem', // add horizontal padding
          py: '1rem', // add vertical padding
          '& .MuiButton-label': {
            textTransform: 'capitalize',
            fontWeight: 'bold',
            fontSize: '1.2rem' // set font size to 1.2rem
          }
        }}
      >
        Sign up for free
      </Button>
    </Link>
    <Link to="/template2" style={{ textDecoration: 'none', width: '100%' }}>
              <Button size="large" type="submit" variant="outlined" sx={{ ml: 2, borderColor: 'white',  borderRadius: '50px', '&:hover': { borderColor: 'white' } }}>
                <Typography variant="subtitle1" sx={{ color:"white", fontWeight: 'bold', textTransform: 'capitalize' }}>
                  Try editor
                </Typography>
              </Button>
              </Link>
  </Box>
</Box>


</AnimatedBox>

        <Box sx={{ mt: 0, position: 'relative', zIndex: 10000 }}>
          <Scroll to="nextsection1" smooth={true} duration={500}>
            <Button
              variant="contained"
              color="secondary"
              sx={{
                position: 'absolute',
                boxShadow: 'none',
                bottom: 120,
                left: '50%',
                transform: 'translateX(-50%)',
                background: 'transparent',
                '&:hover': {
                  background: 'transparent',
                  boxShadow: 'none'
                },
                '&:focus': {
                  background: 'transparent',
                  boxShadow: 'none'
                },
                '&:active': {
                  background: 'transparent',
                  boxShadow: 'none'
                },
                '@media (max-width:600px)': {
                  bottom: 170
                },
                '@media (max-width:400px)': {
                  bottom: 100
                }
              }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
               
                <IconButton
                  sx={{
                    width: 40,
                    color: 'white',
                    boxShadow: 'none',
                    '&:hover': {
                      background: 'transparent',
                      boxShadow: 'none'
                    },
                    '&:focus': {
                      background: 'transparent',
                      boxShadow: 'none'
                    },
                    '&:active': {
                      background: 'transparent',
                      boxShadow: 'none'
                    }
                  }}>
                  <img src="/assets/images/home/Scrolldown.svg" alt="Scrolldown"  />
                </IconButton>
              </Box>
            </Button>
          </Scroll>
        </Box>
</Box>

<Box id="nextsection1" full-width sx={{py:20, backgroundColor:'#A5E0FC', pt:[0,20] }}>
<Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', maxWidth: '1200px', margin: 'auto' }}>

        
<Grid container sx={{  }}>
  <Grid item xs={12} md={6}>
    <img src="/assets/emptyscene.jpg" alt="your-image" />
  </Grid>
  <Grid item xs={12} md={6}>
    <Box component="main" sx={{ m: 0, width: '50%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', '@media (max-width: 2000px)': { width: '100%' } }}>
      <Box sx={{ mx: { xs: 2, sm: 2, md: 2 }, pt: 0, mt: 0, zIndex: 1 }}>
        <Typography align="left" color="#000F37" gutterBottom sx={{ pt: 0, mt: 0, fontSize: {xs: '2rem', sm:'2.5rem', md: '3rem'}, fontFamily: 'Archivo Black', lineHeight: 1 }}>
        Pick a template and away you go
        </Typography>
        <Typography variant="subtitle1" align="left" gutterBottom color="#000F37" sx={{ fontWeight:'regular', fontSize: {xs: '1rem', sm:'1rem', md: '1rem'}, my:4   }}>
        Craft breathtaking 3D scenes for web and VR with our straightforward interface and vast template library. No prior experience needed.        </Typography>
      </Box>
      <Box sx={{my:0, mx: { xs: 2, sm: 0, md: 2 }, pt: 2, zIndex: 1 }}>
        <Link to="/signup" style={{ textDecoration: 'none', display: 'inline-flex' }}>
        <Button
  size="large"
  type="submit"
  variant="contained"
  sx={{
    whiteSpace: 'nowrap',
    borderRadius: '50px',
    px: '1.5rem',
    py: '1rem',
    background: '#000F37',
    '& .MuiButton-label': {
      textTransform: 'capitalize',
      fontWeight: 'bold',
      fontSize: '1.2rem',
      color: 'white'
    }
  }}
>
  Get started for free
</Button>
        </Link>
      </Box>
    </Box>
  </Grid>
</Grid>
</Box>
</Box>


<Box full-width sx={{py:20, backgroundColor:'#171717' }}>
<Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', maxWidth: '1200px', margin: 'auto' }}>
<Grid container sx={{  }}>
  <Grid item xs={12} md={6} sx={{order: {xs: 1, md: 2}}}>
    <img src="/assets/bootsscene2.jpg" alt="your-image" />
  </Grid>
  <Grid item xs={12} md={6} sx={{order: {xs: 2, md: 1}}}>
    <Box component="main" sx={{ m: 0, width: '50%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', '@media (max-width: 2000px)': { width: '100%' } }}>
      <Box sx={{ mx: { xs: 2, sm: 2, md: 2 }, pt: 0, mt: 0, zIndex: 1 }}>
        <Typography align="left" color="white" gutterBottom sx={{ pt: 0, mt: 0, fontSize: {xs: '2rem', sm:'2.5rem', md: '3rem'}, fontFamily: 'Archivo Black', lineHeight: 1 }}>
        Boost your online presence and drive sales         </Typography>
        <Typography variant="subtitle1" align="left" gutterBottom color="white" sx={{ fontWeight:'regular', fontSize: {xs: '1rem', sm:'1rem', md: '1rem'}, my:4   }}>
        Attract and captivate audiences with engaging 3D content, directing traffic to your online sales channels. Seamlessly incorporate affiliate links within your 3D scenes.        </Typography>
      </Box>
      <Box sx={{my:0, mx: { xs: 2, sm: 0, md: 2 }, pt: 2, zIndex: 1 }}>
        <Link to="/signup" style={{ textDecoration: 'none', display: 'inline-flex' }}>
          <Button
            size="large"
            type="submit"
            color="secondary"
            variant="contained"
            sx={{
              whiteSpace: 'nowrap',
              borderRadius: '50px', // set borderRadius to 50px for a circular shape
              px: '1.5rem', // add horizontal padding
              py: '1rem', // add vertical padding
              '& .MuiButton-label': {
                textTransform: 'capitalize',
                fontWeight: 'bold',
                fontSize: '1.2rem' // set font size to 1.2rem
              }
            }}
          >
            Get started for free
          </Button>
        </Link>
      </Box>
    </Box>
  </Grid>
</Grid>
</Box>
</Box>

<Box full-width sx={{py:20, backgroundColor:'#780016' }}>
<Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', maxWidth: '1200px', margin: 'auto' }}>
<Grid container sx={{  }}>
  <Grid item xs={12} md={6} >
  <Grid container spacing={0} justifyContent="center" sx={{ mt: { xs: 0, sm: 0 }, p: { xs: 5, sm: 5 }, display: 'flex' }}>
          <Box sx={{ position: 'relative', width: '100%', height: 'auto' }}>
            <video src="https://spestect.sirv.com/AS.mp4" muted controls loop style={{ width: '100%', height: 'auto', borderRadius: '8px' }} />
          </Box>
        </Grid>
  </Grid>
  <Grid item xs={12} md={6}>
    <Box component="main" sx={{ m: 0, width: '50%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', '@media (max-width: 2000px)': { width: '100%' } }}>
      <Box sx={{ mx: { xs: 2, sm: 2, md: 2 }, pt: 0, mt: 0, zIndex: 1 }}>
        <Typography align="left" color="#E8BFE8" gutterBottom sx={{ pt: 0, mt: 0, fontSize: {xs: '2rem', sm:'2.5rem', md: '3rem'}, fontFamily: 'Archivo Black', lineHeight: 1 }}>
        All platforms, all devices        </Typography>
        <Typography variant="subtitle1" align="left" gutterBottom color="#E8BFE8" sx={{ fontWeight:'regular', fontSize: {xs: '1rem', sm:'1rem', md: '1rem'}, my:4   }}>
        All scenes are fully responsive and optimised for all devices, including virtual reality, so you can be sure that your content will look great and function seamlessly no matter how it's viewed.
        </Typography>
      </Box>
      <Box sx={{my:0, mx: { xs: 2, sm: 0, md: 2 }, pt: 2, zIndex: 1 }}>
        <Link to="/signup" style={{ textDecoration: 'none', display: 'inline-flex' }}>
          <Button
            size="large"
            type="submit"
            color="secondary"
            variant="contained"
            sx={{
              whiteSpace: 'nowrap',
              borderRadius: '50px', // set borderRadius to 50px for a circular shape
              px: '1.5rem', // add horizontal padding
              py: '1rem', // add vertical padding
              background: '#E8BFE8',
              color: 'black', // set font color to black

              '& .MuiButton-label': {
                textTransform: 'capitalize',
                fontWeight: 'bold',
                fontSize: '1.2rem', // set font size to 1.2rem
                color: 'white'
              }
            }}
          >
            Get started for free
          </Button>
        </Link>
      </Box>
    </Box>
  </Grid>
</Grid>
</Box>
</Box>


<Box full-width sx={{backgroundColor:'#E7E7E8' }}>
<Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', maxWidth: '1200px', margin: 'auto' }}>
        <Box id="nextSection" x={{ p: 1}} />
        <AnimatedBox
          ref={ref}
          style={fadeAndMoveUp}
          sx={{ mt:10 , px: 5, flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', pt: { xs: 0, sm: 0, md: 0 } }}>
            
        <Typography align="left" color="black" gutterBottom sx={{ pt: 0, my: 3, fontSize: {xs: '2rem', sm:'2rem', md: '2.5rem'}, fontFamily: 'Archivo Black', lineHeight: 1 }}>
        How it works       </Typography>
    
          <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }} sx={{ pb: 20, display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
            <Grid item xs={12} md={6} sx={{ width: '100%', '@media (min-width: 600px)': { width: 'auto' } }}>
              <Box sx={{ background: 'white', borderRadius: '8px', p: 5, pb: 0, backdropFilter: 'blur(10px)' }}>
                {' '}
                <Typography  align="left" color="242C36" sx={{ mb: 1,fontFamily: 'Archivo Black'}}>
                  1. Pick a template
                </Typography>
                <Typography variant="body2" align="left" color="242C36" sx={{ mb: 2 }}>
                  Unlock the Power of 3D with our Extensive Template Library
                </Typography>
                <img src="/assets/images/home/featuretemp1.jpg" alt="hero" style={{ width: '100%', maxHeight: '500px', objectFit: 'cover' }} />
              </Box>
            </Grid>
            <Grid item xs={12} md={6} sx={{ width: '100%', '@media (min-width: 600px)': { width: 'auto' } }}>
              <Box sx={{ background: 'white', borderRadius: '8px', p: 5, pb: 0, backdropFilter: 'blur(10px)' }}>
                <Typography align="left" color="242C36" sx={{ mb: 1,fontFamily: 'Archivo Black'}}>
                  2. Drag and drop assets
                </Typography>
                <Typography variant="body2" align="left" color="242C36" sx={{ mb: 2 }}>
                  Effortlessly Create with our Drag-and-Drop Asset System
                </Typography>
                <img src="/assets/images/home/featuretemp2.jpg" alt="hero" style={{ width: '100%', maxHeight: '500px', objectFit: 'cover' }} />
              </Box>
            </Grid>

            <Grid item xs={12} md={6} sx={{ width: '100%', '@media (min-width: 600px)': { width: 'auto' } }}>
              <Box sx={{ background: 'white', borderRadius: '8px', p: 5, pb: 0, mb: 0, backdropFilter: 'blur(10px)' }}>
                <Typography align="left" color="242C36" sx={{ mb: 1,fontFamily: 'Archivo Black'}}>
                  3. Edit and Update
                </Typography>
                <Typography variant="body2" align="left" color="242C36" sx={{ mb: 2 }}>
                  Intuitive UI means no Previous 3D Experience is Necessary
                </Typography>
                <img src="/assets/images/home/featuretemp3.jpg" alt="hero" style={{ width: '100%', maxHeight: '500px', objectFit: 'cover' }} />
              </Box>
            </Grid>
            <Grid item xs={12} md={6} sx={{ width: '100%', '@media (min-width: 600px)': { width: 'auto' } }}>
              <Box sx={{ background: 'white', borderRadius: '8px', p: 5, pb: 0, backdropFilter: 'blur(10px)' }}>
                {' '}
                <Typography align="left" color="242C36" sx={{ mb: 1,fontFamily: 'Archivo Black'}}>
                  4. Publish, share and embed
                </Typography>
                <Typography variant="body2" align="left" color="#242C36" sx={{ mb: 2 }}>
                  One-Click Publish, Share, and Embed to take your 3D scene live
                </Typography>
                <img src="/assets/images/home/featuretemp4.jpg" alt="hero" style={{ width: '100%', maxHeight: '500px', objectFit: 'cover' }} />
              </Box>
            </Grid>
          </Grid>
        </AnimatedBox>
        </Box>
        </Box>

        <Box full-width sx={{backgroundColor:'white' }}>
<Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', maxWidth: '1200px', margin: 'auto' }}>
        <Box
          sx={{
            px: 5,
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start', // update to flex-start
            mt: 10,
            '@media (max-width: 600px)': {
              '.MuiTypography-h3': {
                fontSize: '2rem'
              },
              '.MuiTypography-subtitle1': {
                fontSize: '1rem'
              }
            }
          }}>
           <Typography align="left" color="black" gutterBottom sx={{ pt: 0, my: 3, fontSize: {xs: '2rem', sm:'2rem', md: '2.5rem'}, fontFamily: 'Archivo Black', lineHeight: 1 }}>
        More templates coming soon...      </Typography>
        </Box>

        <section>
          <Grid container spacing={4} justifyContent="center" sx={{ mt: { xs: 0, sm: 0 }, pb: 20, px: { xs: 5, sm: 5 }, display: 'flex' }}>
            <Grid item xs={12} md={4}>
              <Card
                sx={{
                  height: '100%',
                  background: '#171717',
                  border: '1px solid white',
                  borderRadius: '8px',
                  textAlign: 'left'
                }}>
                <CardContent sx={{ color: '#242C36', p:0 }}>
                  <img src="/assets/bootsscene2.jpg" alt="Scrolldown" width="100%" style={{ borderRadius:5}} />
                  <Typography align="left" color="white" sx={{ px:1, py:1, fontFamily: 'Archivo Black' }}>
                    Product showcases
                  </Typography>
                  <Typography variant="body2" color="white" sx={{ px:1}}>
                  Perfectly lit scenes to showcase products. Upload your models and include brand or product imagery on background posters.                </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card
                sx={{
                  height: '100%',
                  background: '#EDEBE5',
                  border: '1px solid white',
                  borderRadius: '8px',
                  textAlign: 'left'
                }}>
                <CardContent sx={{ color: '#242C36', p:0 }}>
                  <img src="/assets/tshirt.webp" alt="Scrolldown" width="100%" style={{ borderRadius:5}} />
                  <Typography align="left" color="#242C36" sx={{ px:1, py:1, fontFamily: 'Archivo Black' }}>
                    Clothing/Merchandise 
                  </Typography>
                  <Typography variant="body2" sx={{ px:1}}>
                    Drop your designs on to a range of pre designed models. Increase sales and drive traffic to your online store with 3D e-commerce templates.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card
                sx={{
                  height: '100%',
                  background: '#37B1FF',
                  border: '1px solid white',
                  borderRadius: '8px',
                  textAlign: 'left'
                }}>
                <CardContent sx={{ color: '#242C36', p:0 }}>
                  <img src="/assets/phone.webp" alt="Scrolldown" width="100%" style={{ borderRadius:5}}/>
                  <Typography align="left" color="#242C36" sx={{ px:1, py:1, fontFamily: 'Archivo Black' }}>
                    Device Mock ups
                  </Typography>
                  <Typography variant="body2" sx={{ px:1}}>
                    Showcase apps and websites in a range of 3D device mock ups. Download a render or embed directly in a website.{' '}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </section>
      
        
   
    </Box>
    </Box>

    <Box full-width sx={{backgroundColor:'#E7E7E8' }}>
<Box sx={{ py: 10, px:2, flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', maxWidth: '1200px', margin: 'auto' }}>
<Typography align="center" color="black" gutterBottom sx={{  fontSize: {xs: '2rem', sm:'2rem', md: '2.5rem'}, fontFamily: 'Archivo Black', lineHeight: 1 }}>
“Absolute Scenes allows me to easily share engaging experiences with my audience when I drop new products”    </Typography>
<Typography  variant="body2" align="center" color="black" gutterBottom sx={{ py:2, lineHeight: 1,  }}>
James Black, Content Creator    </Typography>
</Box>
</Box>

    <Box full-width sx={{py:20, backgroundColor:'#A5E0FC' }}>
<Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', maxWidth: '1200px', margin: 'auto' }}>
<Grid container sx={{  }}>
  <Grid item xs={12} md={6} >
 
  </Grid>
  
    <Box component="main" sx={{ m: 0, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', '@media (max-width: 2000px)': { width: '100%' } }}>
      <Box sx={{ mx: { xs: 2, sm: 2, md: 2 }, pt: 0, mt: 0, zIndex: 1 }}>
        <Typography align="left" color="#000F37" gutterBottom sx={{ pt: 0, mt: 0, fontSize: {xs: '2rem', sm:'2.5rem', md: '3rem'}, fontFamily: 'Archivo Black', lineHeight: 1 }}>
        Get started today       </Typography>
        
      </Box>
      <Box sx={{my:0, mx: { xs: 2, sm: 0, md: 2 }, pt: 2, zIndex: 1 }}>
        <Link to="/signup" style={{ textDecoration: 'none', display: 'inline-flex' }}>
          <Button
            size="large"
            type="submit"
            color="secondary"
            variant="contained"
            sx={{
              whiteSpace: 'nowrap',
              borderRadius: '50px', // set borderRadius to 50px for a circular shape
              px: '1.5rem', // add horizontal padding
              py: '1rem', // add vertical padding
              background: '#000F37',
              color: 'white', 
              '& .MuiButton-label': {
                textTransform: 'capitalize',
                fontWeight: 'bold',
                fontSize: '1.2rem', // set font size to 1.2rem
                color: 'white'
              }
            }}
          >
            Get started for free
          </Button>
        </Link>
      </Box>
    </Box>
  
</Grid>
</Box>
</Box>
<Box sx={{ backgroundColor: 'black', padding: '2rem' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
        <Box sx={{ mx: '1rem' }}>
          <a href="https://www.tiktok.com/@absolutescenes3d?is_from_webapp=1&sender_device=pc"><FontAwesomeIcon color='white' icon={faTiktok} size="2x" /></a>
        </Box>
       
        <Box sx={{ mx: '1rem' }}>
          <a href="https://instagram.com/absolutescenes3d?igshid=YmMyMTA2M2Y="><FontAwesomeIcon color='white'  icon={faInstagram} size="2x" /></a>
        </Box>
       
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', marginTop: '1rem', textAlign: 'center' }}>
        <Typography variant="body1" color='white'  sx={{ mr: '1rem',fontSize: {xs: '0.8rem', sm:'0.8rem', md: '0.8rem'} }}>Contact: hello@absolute-scenes.com</Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', marginTop: '0.5rem', textAlign: 'center' }}>
        <Typography variant="body1" color='white'  sx={{ mr: '1rem', fontSize: {xs: '0.8rem', sm:'0.8rem', md: '0.8rem'} }}>Copyright © 2023 T.Webb Limited. All rights reserved.
</Typography>
</Box>
<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', marginTop: '1rem', textAlign: 'center' }}>
<Typography variant="body1" color='white'  sx={{ mr: '1rem', fontSize: {xs: '0.8rem', sm:'0.8rem', md: '0.8rem'} }}><Link style={{ color: 'white' }} to="/privacy-policy">Privacy Policy</Link>
</Typography>
<Typography variant="body1" color='white'  sx={{ mr: '1rem', fontSize: {xs: '0.8rem', sm:'0.8rem', md: '0.8rem'} }}><Link style={{ color: 'white' }} to="/terms-of-service">Terms of Service</Link>
</Typography>
<Typography variant="body1" color='white'  sx={{ mr: '1rem', fontSize: {xs: '0.8rem', sm:'0.8rem', md: '0.8rem'} }}><Link style={{ color: 'white' }} to="/cookie-policy">Cookie Policy</Link>
</Typography>
</Box>
    </Box>
    </Box>
    
  )
}
