import { Navigate, useRoutes } from 'react-router-dom';
import { Suspense } from 'react'
import { Loader } from '@react-three/drei'

// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import SignupPage from './pages/SignupPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import TemplateEditor from './pages/TemplateEditor';
import Scene2 from './pages/TemplateEditor3';
import HomePage from './pages/HomePage';
import { PrivacyPolicy, TermsOfService, CookiePolicy } from './pages/Policies.js';

import SceneWrapper from './pages/SceneWrapper';
// ----------------------------------------------------------------------

const PrivateRoute = ({ element, isAuthenticated }) => {
  return isAuthenticated ? element : <Navigate to="/login" replace />;
};

export default function Router({ isAuthenticated }) {
  const routes = useRoutes([
    {
      path: '/',
      element: <Navigate to="/home" replace />,
    },
    {
      path: 'home',
      element: isAuthenticated ? <Navigate to="/dashboard" replace /> : <HomePage />,
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/products" />, index: true },
        { path: 'app', element: <PrivateRoute element={<DashboardAppPage />} isAuthenticated={isAuthenticated} /> },
        { path: 'user', element: <PrivateRoute element={<UserPage />} isAuthenticated={isAuthenticated} /> },
        { path: 'products', element: <PrivateRoute element={<ProductsPage />} isAuthenticated={isAuthenticated} /> },
        { path: 'blog', element: <PrivateRoute element={<BlogPage />} isAuthenticated={isAuthenticated} /> },
      ],
    },
    {
      path: 'template2', 
      element:  <Suspense fallback={<Loader />}>
         <TemplateEditor /> 
         </Suspense>
    },
    {
      path: 'template/:templateType/:id',
      element: (
        <Suspense fallback={<Loader />}>
          <SceneWrapper key={`scene-wrapper-${Date.now()}`} />
        </Suspense>
      ),
    },
    {
      path: 'login',
      element: isAuthenticated ? <Navigate to="/dashboard" replace /> : <LoginPage />,
    },
    {
      path: 'signup',
      element: isAuthenticated ? <Navigate to="/dashboard" replace /> : <SignupPage />,
    },
    {
      path: 'forgotpassword',
      element: isAuthenticated ? <Navigate to="/dashboard" replace /> : <ForgotPassword />,
    },
    {
      path: 'recover/:token', 
      element:  
         <ResetPassword /> 
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />},
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
    {
      path: 'privacy-policy',
      element: <PrivacyPolicy />,
    },
    {
      path: 'terms-of-service',
      element: <TermsOfService />,
    },
    {
      path: 'cookie-policy',
      element: <CookiePolicy />,
    },

  ]);

  return routes;
}
