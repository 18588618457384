import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// @mui
import { Stack, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import { Link } from "react-router-dom";
// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let errors = {};

    if (!email) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Email is invalid';
    }

    if (!password) {
      errors.password = 'Password is required';
    }

    setErrors(errors);

    return Object.keys(errors).length === 0; // return true if there are no errors
  };

  const handleClick = async () => {
    if (!validateForm()) {
      return;
    }
  
    try {
      const response = await axios.post('https://api.absolute-scenes.com/login', {
        email,
        password,
      });
  
      if (response.status === 200) {
        const token = response.data.token;
        localStorage.setItem('jwtToken', token);
        const user = response.data;
        localStorage.setItem('email', email);
        
        navigate('/dashboard');
      } else {
        setError('Invalid email or password');
      }
    } catch (error) {
      console.error(error);
      if (error.response && error.response.data.message === 'service_login_get_user: mongo: no documents in result') {
        setError('Incorrect email or password');
      } else {
        setError('Incorrect email or password');
      }
    }
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField
          name="email"
          label="Email address"
          error={errors.email}
          helperText={errors.email}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          error={errors.password}
          helperText={errors.password}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
      <Typography variant="body2">
        <Link variant="subtitle2" underline="hover" to='/forgotpassword'>
          Forgot password?
        </Link>
        </Typography>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Login
      </LoadingButton>
      {error && (
  <Typography variant="body2" sx={{ mt: 1, mb: 2 }} color="error">
    {error}
  </Typography>
)}
    </>
  );
}
