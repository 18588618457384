import { useState } from 'react';
import axios from 'axios';

// @mui
import { Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

export default function ForgotPasswordForm() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let errors = {};

    if (!email) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Email is invalid';
    }

    setErrors(errors);

    return Object.keys(errors).length === 0; // return true if there are no errors
  };

  const handleClick = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      const response = await axios.post('https://api.absolute-scenes.com/recover/password', {
        email,
      });

      if (response.status === 200) {
        // update the state to display success message
        setSuccess(true);
      } else {
        setError('Error sending email');
      }
    } catch (error) {
      console.error(error);
      setError('Error sending email');
    }
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField
          name="email"
          label="Email address"
          error={errors.email}
          helperText={errors.email}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick} sx={{ mt: 2, mb: 2 }}>
        Reset Password
      </LoadingButton>
      
      {error && (
        <Typography variant="body2" sx={{ mt: 1, mb: 2 }} color="error">
          {error}
        </Typography>
      )}
      
      {success && (
        <Typography variant="body2" sx={{ mt: 1, mb: 2 }} color="success">
          We've sent you a link to reset your password. Please check your mailbox.
        </Typography>
      )}
    </>
  );
}
