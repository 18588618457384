import * as THREE from 'three'
import { useEffect, useLayoutEffect, Suspense, useState, useRef } from 'react'
import { Canvas, applyProps, useFrame, AnimationMixer } from '@react-three/fiber'
import {
  useVideoTexture,
  Box,
  useTexture,
  Sphere,
  Cylinder,
  Center,
  AccumulativeShadows,
  RandomizedLight,
  OrbitControls,
  Environment,
  useGLTF,
  TorusKnot,
  Image,
  Float,
  ContactShadows,
  Decal,
  PerspectiveCamera
} from '@react-three/drei'
import { FlakesTexture } from 'three-stdlib'
import { VRButton, XR, Controllers } from '@react-three/xr'
import { folder, useControls, LevaPanel, Leva, button } from 'leva'

import { Model } from '../sections/home/Model';
import './styles.css';

export default function Scene2() {
  const [Background, setBackground] = useState('#004843')
  const { Image2 } = useControls({
    Image2: { image: 'https://spestect.sirv.com/pexels-nati-13610999.jpg', label: 'Poster' }
  })

  const [backgroundColor, setBackgroundColor] = useState('#000000') // initialize with initial color

  useEffect(() => {
    const intervalId = setInterval(() => {
      const randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16)
      setBackground(randomColor)
    }, 6000)

    return () => clearInterval(intervalId)
  }, [])

  return (
    <>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'black',
          color: 'white',
          margin: 0,
          padding: 0
        }}>
          <Leva hidden />
        <Canvas class="canvas" shadows camera={{ position: [0, 2, 1], fov: 25 }} style={{ background: [Background],  }} >
          <XR>
          <PerspectiveCamera makeDefault position={[-0.3, 1, 1.5]} />

            <group scale={1} position={[0, 0, 0]}></group>

            <Sphere castShadow receiveShadow args={[0.05, 50, 50]} position={[1.5, 0.5, 0]}>
              <meshStandardMaterial color={Background} />
            </Sphere>
            <Sphere castShadow receiveShadow args={[0.07, 50, 50]} position={[1.3, 0.7, 0.3]}>
              <meshStandardMaterial color={Background} />
            </Sphere>
            <Sphere castShadow receiveShadow args={[0.05, 50, 50]} position={[1.4, 0.5, -0.3]}>
              <meshStandardMaterial color={Background} />
            </Sphere>
            <Sphere castShadow receiveShadow args={[0.07, 50, 50]} position={[-1.5, 0.7, 0]}>
              <meshStandardMaterial color={Background} />
            </Sphere>
            <Sphere castShadow receiveShadow args={[0.03, 50, 50]} position={[-1.2, 0.7, 0.3]}>
              <meshStandardMaterial color={Background} />
            </Sphere>
            <Sphere castShadow receiveShadow args={[0.05, 50, 50]} position={[-1.4, 0.5, -0.3]}>
              <meshStandardMaterial color={Background} />
            </Sphere>

            <group>
              <spotLight intensity={0.5} args={['#ffffff', 1.5, 100, 20, 1]} rotation={[0, 0, 0]} position={[0, 2, 0]} castShadow />
            </group>
            <Float>
              <Model position={[0, -0.28, 0]} />
            </Float>
            
            <Environment preset="warehouse" />
            <group scale={[1, 1, 1]} position={[0, 1.6, -0]} rotation={[0, 0, 0]}>
              <Wall Background={Background} />
            </group>
          </XR>
        </Canvas>
      </div>
    </>
  )
}



function Wall(props) {
  const { scene, materials } = useGLTF('/bgscene2.glb')
  useLayoutEffect(() => {
    scene.traverse((obj) => obj.isMesh && (obj.receiveShadow = obj.castShadow = true))
    applyProps(materials.Material, {
      color: props.Background
    })
  })
  return <primitive object={scene} {...props} rotation={[0, Math.PI / 2, 0]} position={[0, 0, 0]} />
}



