import * as THREE from 'three'
import { useEffect, useLayoutEffect, Suspense, useState, useRef } from 'react'
import { Canvas, applyProps, useFrame, AnimationMixer } from '@react-three/fiber'
import {
  useVideoTexture,
  Box,
  useTexture,
  Sphere,
  Cylinder,
  Center,
  AccumulativeShadows,
  RandomizedLight,
  OrbitControls,
  Environment,
  useGLTF,
  TorusKnot,
  Image,
  Float,
  Loader,
  ContactShadows,
  Decal
} from '@react-three/drei'
import { FlakesTexture } from 'three-stdlib'
import { VRButton, XR, Controllers } from '@react-three/xr'
import { folder, useControls, LevaPanel, Leva, button } from 'leva'
import axios from 'axios'
import { useParams } from 'react-router-dom';
import { Scale } from '@mui/icons-material'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { IconButton, Button, Typography } from "@mui/material";
import TextField from '@mui/material/TextField';

export default function Scene2() {


  const [formState, setFormState] = useState({
    Title1: 'Title',
    Background: '#93e2ff',
 
  });
  
  const Title1Ref = useRef(formState.Title1);
  const BackgroundRef = useRef(formState.Background);
 
  
  const handleSubmit = () => {
    Submit(Title1Ref.current, BackgroundRef.current, model.fileURL, ScaleRef.value);
  };
  
  const handleTitle1Change = (value) => {
    Title1Ref.current = value;
  };

  const handleBackgroundChange = (value) => {
    BackgroundRef.current = value;
    const canvas = document.querySelector('canvas');
    if (canvas) {
      canvas.style.background = value;
    }
  };

  useEffect(() => {
    Title1Ref.current = formState.Title1;
    BackgroundRef.current = formState.Background;
  
  }, [formState.Title1]);
  
  const [{ Title:control }, setTitle1] = useControls(() => ({
    Title1: {
      value: Title1Ref.current,
      label: 'Title',
      onChange: handleTitle1Change,
    },
  }));
  
  const [{ Backgroundcontrol2 }, setBackground] = useControls(() => ({
    
    Background: {
      value: BackgroundRef.current,
      label: 'Background',
      onChange: handleBackgroundChange,
      transient: false
    },
   
  }));
  
  const [templateType, setTemplateType] = useState('');
  const [data, setData] = useState({});
  const jwtToken = localStorage.getItem("jwtToken");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    if (jwtToken) {
      setIsLoggedIn(true);
    }
  }, [jwtToken]);
  const { id } = useParams();
  useEffect(() => {
    axios
      .get(`https://api.absolute-scenes.com/template/${id}`, {
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Cache-Control": "no-cache, max-age=0"
        }
      })
      .then((res) => {
        if (res.data && res.data.product.file) {
          setData(res.data);
          const templateType =res.data.scene.ground_color;
          setTemplateType(templateType)
          setTitle1({ Title1: res.data.title });
          setModel({ fileURL: res.data.product.file ? res.data.product.file : "https://spestect.sirv.com/Torus.glb" });
          const fileURL = res.data.product.file;
    selectedFileRef.current = fileURL;
          setBackground ({Background: res.data.scene.background_color})
          setTcolor ({Tcolor: res.data.scene.skybox_image})
          
          setImg({ fileURL2: res.data.posters[0].url ? res.data.posters[0].url + "?not-from-cache-please" : ''});
          const fileURL2 = res.data.posters[0].url;
    selectedFileRef2.current = fileURL2;
    setname ({Name: res.data.details.name})
          setprice ({Price: res.data.details.price})
setURL ({URL2: res.data.details.linkurl})
setlinkcopy ({Linkcopy: res.data.details.linkcopy})
setFontcolor ({Fontcolor: res.data.details.fontcolor})
        }
      });
  }, [id]);
  
  const [img, setImg] = useState({ fileURL2: "https://spestect.sirv.com/pexels-nati-13610999.jpg" });
  
  const [selectedFile2, setSelectedFile2] = useState(null);

  const fileInputRef2 = useRef(null);

  const onFileInputChange2 = (event) => {
    const file2 = event.target.files[0];

    //new code 
    const formData = new FormData();
    formData.append("myFile", file2);

    const config = {
      headers: {
        Authorization:
        `Bearer ${jwtToken}`,
        "Content-Type": "multipart/form-data",
      },
    };
    axios
    .post("https://api.absolute-scenes.com/upload", formData, config)
    .then((res) => {
      console.log("Upload success:", res.data);
      setImg({ fileURL2: res.data.url});
      selectedFileRef2.current = res.data.url;
    })
    .catch((error) => {
      console.log("Upload error:", error.message);
    });
  
  
    //end of new code

    setSelectedFile2(file2);
   
  };

  const selectedFileRef2 = useRef(null);
  
  useEffect(() => {
    if (selectedFile2) {
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          const fileURL2 = URL.createObjectURL(selectedFile2);
          selectedFileRef2.current = fileURL2;

      setImg({ fileURL2 }); 
        },
        false
      );
      reader.readAsArrayBuffer(selectedFile2);
    }
  }, [selectedFile2]);

  const showFileInputButton2 = () => {
    fileInputRef2.current.click();
  };

  const TcolorRef = useRef(null)
const [{ Tcolor }, setTcolor] = useControls(() => ({
  Design: folder({
    Tcolor: {
      value: 'white',
      label: 'T-Shirt Color',
      onChange: (v) => {
        // imperatively update the world after Leva input changes
        TcolorRef.value = v
      },
      transient: false
    }
  })
}))

  const { upload2 } = useControls({
   Design: folder ({
    Upload: button(showFileInputButton2)
   },{order:2})
})
  

  const PositionRef = useRef(null)
  const [{ Position }, setPosition] = useControls(() => ({
   
  }))
  
  const ScaleRef = useRef(null)
  const [{ Scale }, setScale] = useControls(() => ({
    
  }))

  const NameRef = useRef(null)
  const [{ Name }, setname] = useControls(() => ({
      Details: folder({
        Name: {
          value: 'Product name',
          label: 'Name',
          onChange: (v) => {
            // imperatively update the world after Leva input changes
            NameRef.value = v
          },
          transient: false
        }
      })
    }))

    const PriceRef = useRef(null)
    const [{ Price }, setprice] = useControls(() => ({
        Details: folder({
          Price: {
            value: '£X.XX',
            label: 'Price',
            onChange: (v) => {
              // imperatively update the world after Leva input changes
              PriceRef.value = v
            },
            transient: false
          }
        })
      }))

  const URL2Ref = useRef(null)
  const [{ URL2 }, setURL] = useControls(() => ({
      Details: folder({
        URL2: {
          value: 'https://www.shopify.com/uk',
          label: 'Link URL',
          onChange: (v) => {
            // imperatively update the world after Leva input changes
            URL2Ref.value = v
          },
          transient: false
        }
      })
    }))

  const LinkcopyRef = useRef(null)
  const [{ Linkcopy }, setlinkcopy] = useControls(() => ({
      Details: folder({
        Linkcopy: {
          value: '',
          label: 'Link copy',
          onChange: (v) => {
            // imperatively update the world after Leva input changes
            LinkcopyRef.value = v
          },
          transient: false
        }
      })
    }))

  const FontcolorRef = useRef(null)
  const [{ Fontcolor }, setFontcolor] = useControls(() => ({
    Details: folder({
      Fontcolor: {
        value: 'white',
        label: 'Font Color',
				onChange: (v) => {
          // imperatively update the world after Leva input changes
          FontcolorRef.value = v
        },
        transient: false
      }
    },{order:4})
  }))



  const {
    Rotate
  } = useControls({
    Rotate: { value: true },
    
  });
  const [uploadedModel, setUploadedModel] = useState(null);

  const [model, setModel] = useState({ fileURL: "https://spestect.sirv.com/Torus.glb" });

  const [selectedFile, setSelectedFile] = useState(null);

  const fileInputRef = useRef(null);

  const onFileInputChange = (event) => {
    const file = event.target.files[0];

    //new code 
    const formData = new FormData();
    formData.append("myFile", file);

    const config = {
      headers: {
        Authorization:
        `Bearer ${jwtToken}`,
        "Content-Type": "multipart/form-data",
      },
        timeout: 60000, // Set the timeout to 60 seconds
    };
    axios
    .post("https://api.absolute-scenes.com/upload", formData, config)
    .then((res) => {
      console.log("Upload success:", res.data);
      setUploadedModel({ fileURL: res.data.url ? res.data.url : "https://spestect.sirv.com/Torus.glb" });
      selectedFileRef.current = res.data.url;
    })
    .catch((error) => {
      console.log("Upload error:", error.message);
    });
  
  
    //end of new code

    setSelectedFile(file);
   
  };

  const selectedFileRef = useRef(null);
  
  useEffect(() => {
    if (selectedFile) {
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          const fileURL = URL.createObjectURL(selectedFile);
          selectedFileRef.current = fileURL;

      setModel({ fileURL }); 
        },
        false
      );
      reader.readAsArrayBuffer(selectedFile);
    }
  }, [selectedFile]);

  const showFileInputButton = () => {
    fileInputRef.current.click();
  };







  const { Publish } = useControls({
    Publish: folder({
    Publish: button(handleSubmit)
    },{order:5},)
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleCopyLink = () => {
    // Copy the URL to the clipboard
    navigator.clipboard.writeText(window.location.href);
    setSnackbarOpen(false);
  };


  function Submit(Title1, Background, fileURL,) {

    const payload = {
        title: Title1,
        scene: {
            background_color: Background,
            camera_rotation: false,
            ground_color: '2',
            skybox_image: TcolorRef.value,
        },
        posters: [
          {
          url: selectedFileRef2.current,
          }
          ],
        product: {
            file: 'null',
          
            rotation: {
                x: 0,
                y: 0,
                z: 0
            },
            scale: ScaleRef.value
        },
        details: {
          name: NameRef.value,
          price: PriceRef.value,
          linkcopy: LinkcopyRef.value,
          linkurl: URL2Ref.value,
          fontcolor: FontcolorRef.value
          },
    }
    
  
   
  
    const config = {
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json",
      },
    };
  
    axios
      .post(`https://api.absolute-scenes.com/template/update/${id}`, payload, config)
      .then((response) => {
        console.log(response.data.url);
        setSnackbarOpen(true); // Open the Snackbar
      })
      .catch((error) => console.log(error));
  }


  return (
    <>
      <input
  type="file"
  onChange={(event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      if (file.size > 10485760) { // 10MB limit
        alert("10MB Limit. Please select a smaller file.");
        event.target.value = "";
      } else {
        onFileInputChange(event);
      }
    };
    reader.readAsDataURL(file);
  }}
  ref={fileInputRef}
  style={{ display: 'none' }}
  accept=".glb, .gltf"
/>
<input
  type="file"
  onChange={(event) => {
    const file = event.target.files[0];
    if (file.size > 2097152) { // 2MB limit
      alert("2MB Limit. Please select a smaller file.");
      event.target.value = "";
    } else {
      onFileInputChange2(event);
    }
  }}
  ref={fileInputRef2}
  style={{ display: 'none' }}
  accept=".png, .webp, .jpg, .jpeg"
/>
      <div>
    {/* Your other components */}
    <Snackbar
  open={snackbarOpen}
  autoHideDuration={null}
  onClose={handleCloseSnackbar}
  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
   style={{ bottom: '100px' }}
>
  <Alert
    onClose={handleCloseSnackbar}
    severity="success"
    color="primary"
    icon={false}
    sx={{
      width: '100%',
      backgroundColor: '#333',
      color: 'white',
    }}
  >
    <Typography
      variant="body"
      gutterBottom
      sx={{
        
        mx: 0,
      }}
    >
      Saved: Share your scene
    </Typography>
    <div style={{ display: 'flex', alignItems: 'center', marginTop:'10px' }}>
      <TextField
        sx={{ mr: 1 }}
        defaultValue={window.location.href}
        variant="outlined"
        size="small"
        fullWidth
        inputProps={{
          readOnly: true,
          style: { fontSize: '0.8rem', color: 'white' },
        }}
      />
      <Button
        variant="contained"
        onClick={handleCopyLink}
        sx={{ height: '100%', whiteSpace: 'nowrap' }}
      >
        Copy Link
      </Button>
    </div>
  </Alert>
</Snackbar>
  </div>
      <div style={{ color: Fontcolor, position: 'absolute', bottom: 0, left: 0, fontSize: '18px', margin: 60, zIndex: 10 }}>
        {Name}
        <br />
        <div style={{ fontWeight: 'bold' }}>{Price}</div>
        <br />

        <div href={URL2} style={{ position: 'relative', marginTop: 10, display: 'inline-block' }}>
  <a style={{ color: Fontcolor, fontSize: '15px', fontWeight: 600, letterSpacing: 2, textDecoration: 'none' }} href={URL2}>
    {Linkcopy}
  </a>

          <div style={{ marginTop: 6, height: 2.5, width: '100%', background: Fontcolor }} />
        </div>
        <br />
      </div>
      


      <Leva hidden={!isLoggedIn} />
      <VRButton
  className="vr-button"
  style={{
    zIndex: 10000,
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    width: '60px',
    height: '60px',
    backgroundColor: 'white',
    color: 'black',
    borderRadius: '50%',
    border: 'none',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    fontFamily: 'Archivo Black',
    fontSize: '1.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'all 0.2s ease-in-out'
  }}
  onMouseEnter={(e) => { e.target.style.backgroundColor = '#f0f0f0'; }}
  onMouseLeave={(e) => { e.target.style.backgroundColor = 'white'; }}
  onMouseDown={(e) => { e.target.style.boxShadow = '0px 0px 2px rgba(0, 0, 0, 0.5)'; }}
  onMouseUp={(e) => { e.target.style.boxShadow = '0px 2px 4px rgba(0, 0, 0, 0.5)'; }}
>
  VR
</VRButton>
      <Canvas class="canvas" shadows camera={{ position: [0, 1.5, 10], fov: 25 }} style={{ background: BackgroundRef.current }}>
        <XR>
        <Controllers/>
          <group scale={1} position={[0, 0, 0]}></group>
          <Sphere castShadow receiveShadow args={[0.05, 50, 50]} position={[1.5, 0.05, 0]}>
            <meshStandardMaterial color={BackgroundRef.current} roughness={0.5}/>
          </Sphere>
          <Sphere castShadow receiveShadow args={[0.07, 50, 50]} position={[1.3, 0.7, 0.3]}>
            <meshStandardMaterial color={BackgroundRef.current} roughness={0.5} />
          </Sphere>
          <Sphere castShadow receiveShadow args={[0.05, 50, 50]} position={[1.4, 0.5, -0.3]}>
            <meshStandardMaterial color={BackgroundRef.current} roughness={0.5} />
          </Sphere>
          <Sphere castShadow receiveShadow args={[0.07, 50, 50]} position={[-1.5, 0.07, 0]}>
            <meshStandardMaterial color={BackgroundRef.current} roughness={0.5} />
          </Sphere>
          <Sphere castShadow receiveShadow args={[0.03, 50, 50]} position={[-1.2, 0.7, 0.3]}>
            <meshStandardMaterial color={BackgroundRef.current} roughness={0.5}/>
          </Sphere>
          <Sphere castShadow receiveShadow args={[0.05, 50, 50]} position={[-1.4, 0.5, -0.3]}>
            <meshStandardMaterial color={BackgroundRef.current} roughness={0.5}/>
          </Sphere>
           <mesh castShadow position={[0, 1.5, -2]} scale={1}>
      <Image
        scale={[1.3, 2.2]}
        url={img.fileURL2 || 'https://spestect.sirv.com/pexels-nati-13610999.jpg'}
        loaderOptions={{
          crossOrigin: 'anonymous',
        }}
      />
    </mesh>

          <group>
           
            <spotLight intensity={0.5} args={['#ffffff', 1.5, 100, 20, 1]} rotation={[0, 0, 0]} position={[0, 2, 0]} castShadow />
           
          </group>
          <Float>
              <Model fileUrl2={img.fileURL2} position={[0, -0.28, 0]} Tcolor={Tcolor}/>
            </Float>

            <group scale={[1, 1, 1]} position={[0, 1.6, -0]} rotation={[0, 0, 0]}>
              <Wall Background={BackgroundRef.current} />
            </group>
            
          <OrbitControls autoRotate={Rotate} autoRotateSpeed={0.2} minPolarAngle={0} maxPolarAngle={Math.PI / 2} maxDistance={8} target={[0, 1, 0]} />
          <Environment preset="warehouse"  />
        </XR>
      </Canvas>
    </>
  )
}

function Base(props) {
  const { Background } = useControls({
    Background: { value: 'goldenrod', label: 'Background' }
  })
  const { scene, materials } = useGLTF('/cyl3.glb')
  useLayoutEffect(() => {
    scene.traverse((obj) => obj.isMesh && (obj.receiveShadow = obj.castShadow = true))
    applyProps(materials.chicken, {
      color: Background,
      roughness: 1,
      normalMap: new THREE.CanvasTexture(new FlakesTexture(), THREE.UVMapping, THREE.RepeatWrapping, THREE.RepeatWrapping),
      'normalMap-repeat': [10, 10],
      normalScale: [0.05, 0.05]
    })
  })
  return <primitive object={scene} {...props} />
}


function Wall(props) {
  const { scene, materials } = useGLTF('/bgscene2.glb')
  useLayoutEffect(() => {
    scene.traverse((obj) => obj.isMesh && (obj.receiveShadow = obj.castShadow = true))
    applyProps(materials.Material, {
      color: props.Background
    })
  })
  return <primitive object={scene} {...props} rotation={[0, Math.PI / 2, 0]} position={[0, 0, 0]} />
}

function Torus() {
  const { Background } = useControls({
    Background: { value: 'goldenrod', label: 'Background' }
  })
  const ref = useRef()
  useFrame((state, delta) => (ref.current.rotation.x = ref.current.rotation.y += delta))
  return (
    <TorusKnot ref={ref} scale={[1.5, 1.5, 1.5]} castShadow receiveShadow args={[0.1, 0.03, 100, 15]} position={[0, 1, 0]}>
      <meshStandardMaterial color={Background} toneMapped={false} />
    </TorusKnot>
  )
}

function Lights(props) {
  const { Background } = useControls({
    Background: { value: 'goldenrod', label: 'Background' }
  })
  const { scene, materials } = useGLTF('/lightsbaked.glb')

  return <primitive object={scene} {...props} />
}

function Model4({ model, ...props }) {
  const { scene, animations } = model
  const mixer = useRef(null)

  useEffect(() => {
    scene.traverse((obj) => obj.isMesh && (obj.receiveShadow = obj.castShadow = true))

    if (animations && animations.length > 0) {
      mixer.current = new THREE.AnimationMixer(scene)
      animations.forEach((clip) => mixer.current.clipAction(clip).play())
    }

    return () => {
      if (mixer.current) mixer.current.stopAllAction()
    }
  }, [scene, animations])

  useFrame((state, delta) => {
    if (mixer.current) {
      mixer.current.update(delta * 1) // <-- set speed here
    }
  })
  return <primitive object={scene} castShadow position={[0, 1, 0]} scale={1} {...props} />
}


function Model(props) {
  const { nodes, materials } = useGLTF('/tshirt2.glb')
  const [design] = useTexture([props.fileUrl2 ||  'https://spestect.sirv.com/pexels-nati-13610999.jpg'])
  materials.Sleeves_FRONT_2669.opacity = 1
  return (
    <group {...props} dispose={null}>
      <group rotation={[-1.6608651, 0.0028782, -0.5035308]}>
        <group rotation={[Math.PI / 2, 0, 0]}>
          <mesh castShadow receiveShadow geometry={nodes.Object_16.geometry} material={materials.Body_FRONT_2664.color.set(new THREE.Color(props.Tcolor))} />
          <mesh castShadow receiveShadow geometry={nodes.Object_14.geometry} material={materials.Body_FRONT_2664} />
          <mesh castShadow receiveShadow geometry={nodes.Object_15.geometry} material={materials.Body_FRONT_2664} />
          <mesh castShadow receiveShadow geometry={nodes.Object_11.geometry} material={materials.Body_FRONT_2664} />
          <mesh castShadow receiveShadow geometry={nodes.Object_10.geometry} material={materials.Body_FRONT_2664}>
            <Decal position={[0, 1.3, 0]} rotation={[0, 0, 0]} scale={[0.2, 0.3, 0.3]} map={design} roughness={1} map-anisotropy={16} />
          </mesh>
          <mesh castShadow receiveShadow geometry={nodes.Object_12.geometry} material={materials.Body_FRONT_2664} />
          <mesh castShadow receiveShadow geometry={nodes.Object_6.geometry} material={materials.Body_FRONT_2664} />
          <mesh castShadow receiveShadow geometry={nodes.Object_8.geometry} material={materials.Body_FRONT_2664} />
          <mesh castShadow receiveShadow geometry={nodes.Object_18.geometry} material={materials.Sleeves_FRONT_2669}>
            <meshStandardMaterial attach="material" color={props.Tcolor} />
          </mesh>
          <mesh castShadow receiveShadow geometry={nodes.Object_20.geometry} material={materials.Sleeves_FRONT_2669}>
            <meshStandardMaterial attach="material" color={props.Tcolor} />
          </mesh>
        </group>
      </group>
    </group>
  )
}


useGLTF.preload('/tshirt2.glb')
