// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/scroll-to-top';
import { StyledChart } from './components/chart';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import UserContext from './contexts/UserContext';
import ReactGA from 'react-ga';

ReactGA.initialize('G-QRQ0NLDXKE');


// ----------------------------------------------------------------------


export default function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [account, setAccount] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem('jwtToken');
  
    if (token) {
      setIsAuthenticated(true);
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      const decodedToken = JSON.parse(atob(base64));
      const email = decodedToken.Email;
      const newAccount = {
        displayName: email,
        email: email,
        photoURL: '/assets/images/avatars/PP.png',
      };
      setAccount(newAccount);
    } else {
      setIsAuthenticated(false);
      setAccount(null);
    }
  }, [location]);
  

  return (
    <ThemeProvider>
      <ScrollToTop />
      <StyledChart />
      <UserContext.Provider value={account}>
        <Router isAuthenticated={isAuthenticated} />
      </UserContext.Provider>
    </ThemeProvider>
  );
}
