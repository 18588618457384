import * as THREE from 'three'
import { useEffect, useLayoutEffect, Suspense, useState, useRef, useTransition } from 'react'
import { Canvas, applyProps, useFrame, AnimationMixer } from '@react-three/fiber'
import {
  useVideoTexture,
  Box,
  useTexture,
  Sphere,
  Cylinder,
  Center,
  AccumulativeShadows,
  RandomizedLight,
  OrbitControls,
  Environment,
  useGLTF,
  TorusKnot,
  Image,
  Float,
  Loader,
  ContactShadows,
  
} from '@react-three/drei'
import { FlakesTexture } from 'three-stdlib'
import { VRButton, XR, Controllers } from '@react-three/xr'
import { folder, useControls, LevaPanel, Leva, button } from 'leva'
import axios from 'axios'
import { useParams } from 'react-router-dom';
import { Scale } from '@mui/icons-material'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import { IconButton, Button, Typography } from "@mui/material";
import { Vologram } from 'volograms-js'

export default function Scene2() {


  const [formState, setFormState] = useState({
    Title1: 'Title',
    Background: '#93e2ff',
 
  });
  
  const Title1Ref = useRef(formState.Title1);
  const BackgroundRef = useRef(formState.Background);
 
  
  const handleSubmit = () => {
    Submit(Title1Ref.current, BackgroundRef.current, model.fileURL);
  };
  
  const handleTitle1Change = (value) => {
    Title1Ref.current = value;
  };

  const handleBackgroundChange = (value) => {
    BackgroundRef.current = value;
    const canvas = document.querySelector('canvas');
    if (canvas) {
      canvas.style.background = value;
    }
  };

  useEffect(() => {
    Title1Ref.current = formState.Title1;
    BackgroundRef.current = formState.Background;
  
  }, [formState.Title1]);
  
  const [{ Title:control }, setTitle1] = useControls(() => ({
    Title1: {
      value: Title1Ref.current,
      label: 'Title',
      onChange: handleTitle1Change,
    },
  }));
  
  const [{ Backgroundcontrol2 }, setBackground] = useControls(() => ({
    
    Background: {
      value: BackgroundRef.current,
      label: 'Background',
      onChange: handleBackgroundChange,
      transient: false
    },
   
  }));
  
  const [templateType, setTemplateType] = useState('');
  const [data, setData] = useState({});
  const jwtToken = localStorage.getItem("jwtToken");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    if (jwtToken) {
      setIsLoggedIn(true);
    }
  }, [jwtToken]);
  const { id } = useParams();
  useEffect(() => {
    axios
      .get(`https://api.absolute-scenes.com/template/${id}`, {
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Cache-Control": "no-cache, max-age=0"
        }
      })
      .then((res) => {
        if (res.data && res.data.product.file) {
          setData(res.data);
          const templateType =res.data.scene.ground_color;
          setTemplateType(templateType)
          setTitle1({ Title1: res.data.title });
          setModel({ fileURL: res.data.product.file ? res.data.product.file : "https://spestect.sirv.com/Torus.glb" });
          const fileURL = res.data.product.file;
    selectedFileRef.current = fileURL;
          setBackground ({Background: res.data.scene.background_color})
          const { x, y, h } = res.data.product.position;
          setImg({ fileURL2: res.data.posters[0].url ? res.data.posters[0].url + "?not-from-cache-please" : '' });
          const fileURL2 = res.data.posters[0].url;
    selectedFileRef2.current = fileURL2;
    setname ({Name: res.data.details.name})
          setprice ({Price: res.data.details.price})
setURL ({URL2: res.data.details.linkurl})
setlinkcopy ({Linkcopy: res.data.details.linkcopy})
setFontcolor ({Fontcolor: res.data.details.fontcolor})
        }
      });
  }, [id]);
  
  const [img, setImg] = useState({ fileURL2: "https://replay-user-assets.s3.eu-west-2.amazonaws.com/tmp/upload-1303476847.jpg"});
  
  const [selectedFile2, setSelectedFile2] = useState(null);

  const fileInputRef2 = useRef(null);

  const onFileInputChange2 = (event) => {
    const file2 = event.target.files[0];

    //new code 
    const formData = new FormData();
    formData.append("myFile", file2);

    const config = {
      headers: {
        Authorization:
        `Bearer ${jwtToken}`,
        "Content-Type": "multipart/form-data",
      },
    };
    axios
    .post("https://api.absolute-scenes.com/upload", formData, config)
    .then((res) => {
      console.log("Upload success:", res.data);
      setImg({ fileURL2: res.data.url});
      selectedFileRef2.current = res.data.url;
    })
    .catch((error) => {
      console.log("Upload error:", error.message);
    });
  
  
    //end of new code

    setSelectedFile2(file2);
   
  };

  const selectedFileRef2 = useRef(null);
  
  useEffect(() => {
    if (selectedFile2) {
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          const fileURL2 = URL.createObjectURL(selectedFile2);
          selectedFileRef2.current = fileURL2;

      setImg({ fileURL2 }); 
        },
        false
      );
      reader.readAsArrayBuffer(selectedFile2);
    }
  }, [selectedFile2]);

  const showFileInputButton2 = () => {
    fileInputRef2.current.click();
  };


  
  const PriceRef = useRef(null)
  const [{ Price }, setprice] = useControls(() => ({
      Vologram: folder({
        Price: {
          value: 'https://twebb288.github.io/1658148174654_ld',
          label: 'Vologram URL',
          onChange: (v) => {
            // imperatively update the world after Leva input changes
            PriceRef.value = v
          },
          transient: false
        }
      })
    }))

  


  const NameRef = useRef(null)
  const [{ Name }, setname] = useControls(() => ({
      Details: folder({
        Name: {
          value: 'Vologram Name',
          label: 'Name',
          onChange: (v) => {
            // imperatively update the world after Leva input changes
            NameRef.value = v
          },
          transient: false
        }
      })
    }))


  const URL2Ref = useRef(null)
  const [{ URL2 }, setURL] = useControls(() => ({
      Details: folder({
        URL2: {
          value: 'https://www.shopify.com/uk',
          label: 'Link URL',
          onChange: (v) => {
            // imperatively update the world after Leva input changes
            URL2Ref.value = v
          },
          transient: false
        }
      })
    }))

  const LinkcopyRef = useRef(null)
  const [{ Linkcopy }, setlinkcopy] = useControls(() => ({
      Details: folder({
        Linkcopy: {
          value: 'Find out more',
          label: 'Link copy',
          onChange: (v) => {
            // imperatively update the world after Leva input changes
            LinkcopyRef.value = v
          },
          transient: false
        }
      })
    }))

  const FontcolorRef = useRef(null)
  const [{ Fontcolor }, setFontcolor] = useControls(() => ({
    Details: folder({
      Fontcolor: {
        value: 'white',
        label: 'Font Color',
				onChange: (v) => {
          // imperatively update the world after Leva input changes
          FontcolorRef.value = v
        },
        transient: false
      }
    },{order:4})
  }))

  const {
    Rotate
  } = useControls({
    Rotate: { value: true },
    
  });
  const [model, setModel] = useState({ fileURL: "https://spestect.sirv.com/Torus.glb" });

  const [selectedFile, setSelectedFile] = useState(null);

  const fileInputRef = useRef(null);

  const onFileInputChange = (event) => {
    const file = event.target.files[0];

    //new code 
    const formData = new FormData();
    formData.append("myFile", file);

    const config = {
      headers: {
        Authorization:
        `Bearer ${jwtToken}`,
        "Content-Type": "multipart/form-data",
      },
        timeout: 60000, // Set the timeout to 60 seconds
    };
    axios
    .post("https://api.absolute-scenes.com/upload", formData, config)
    .then((res) => {
      console.log("Upload success:", res.data);
      setModel({ fileURL: res.data.url ? res.data.url : "https://spestect.sirv.com/Torus.glb" });
      selectedFileRef.current = res.data.url;
    })
    .catch((error) => {
      console.log("Upload error:", error.message);
    });
  
  
    //end of new code

    setSelectedFile(file);
   
  };

  const selectedFileRef = useRef(null);
  
  useEffect(() => {
    if (selectedFile) {
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          const fileURL = URL.createObjectURL(selectedFile);
          selectedFileRef.current = fileURL;

      setModel({ fileURL }); 
        },
        false
      );
      reader.readAsArrayBuffer(selectedFile);
    }
  }, [selectedFile]);

  const showFileInputButton = () => {
    fileInputRef.current.click();
  };






  const { Publish } = useControls({
    Publish: folder({
    Publish: button(handleSubmit)
    },{order:5},)
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleCopyLink = () => {
    // Copy the URL to the clipboard
    navigator.clipboard.writeText(window.location.href);
    setSnackbarOpen(false);
  };
  function Submit(Title1, Background, fileURL,) {

    const payload = {
        title: Title1,
        scene: {
            background_color: Background,
            camera_rotation: false,
            ground_color: '3'
        },
        posters: [
          {
          url: selectedFileRef2.current,
          }
          ],
        product: {
            file: selectedFileRef.current || 'https://spestect.sirv.com/Torus.glb',
           
            rotation: {
                x: 0,
                y: 0,
                z: 0
            },
           
        },
        details: {
          name: NameRef.value,
          price: PriceRef.value,
          linkcopy: LinkcopyRef.value,
          linkurl: URL2Ref.value,
          fontcolor: FontcolorRef.value
          },
        
    
    }
    
  
   
  
    const config = {
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json",
      },
    };
  
    axios
      .post(`https://api.absolute-scenes.com/template/update/${id}`, payload, config)
      .then((response) => {
        console.log(response.data.url);
        setSnackbarOpen(true); // Open the Snackbar
      })
      .catch((error) => console.log(error));
  }

  function VideoMaterial({ url }) {
    const texture = useVideoTexture(url);
    return <meshBasicMaterial map={texture} toneMapped={false} />;
  }

  return (
    <>
      <input
  type="file"
  onChange={(event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      if (file.size > 10485760) { // 10MB limit
        alert("10MB Limit. Please select a smaller file.");
        event.target.value = "";
      } else {
        onFileInputChange(event);
      }
    };
    reader.readAsDataURL(file);
  }}
  ref={fileInputRef}
  style={{ display: 'none' }}
  accept=".glb, .gltf"
/>
<input
  type="file"
  onChange={(event) => {
    const file = event.target.files[0];
    if (file.size > 2097152) { // 2MB limit
      alert("2MB Limit. Please select a smaller file.");
      event.target.value = "";
    } else {
      onFileInputChange2(event);
    }
  }}
  ref={fileInputRef2}
  style={{ display: 'none' }}
  accept=".png, .webp, .jpg, .jpeg"
/>
      <div>
    {/* Your other components */}
    <Snackbar
  open={snackbarOpen}
  autoHideDuration={null}
  onClose={handleCloseSnackbar}
  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
  style={{ bottom: '100px' }}

>
  <Alert
    onClose={handleCloseSnackbar}
    severity="success"
    color="primary"
    icon={false}
    sx={{
      width: '100%',
      backgroundColor: '#333',
      color: 'white',
    }}
  >
    <Typography
      variant="body2"
      gutterBottom
      sx={{
        fontSize:'1em',
        mx: 0,
      }}
    >
      Saved: Share your scene
    </Typography>
    <div style={{ display: 'flex', alignItems: 'center', marginTop:'10px' }}>
      <TextField
        sx={{ mr: 1 }}
        defaultValue={window.location.href}
        variant="outlined"
        size="small"
        fullWidth
        inputProps={{
          readOnly: true,
          style: { fontSize: '0.8rem', color: 'white' },
        }}
      />
      <Button
        variant="contained"
        onClick={handleCopyLink}
        sx={{ height: '100%', whiteSpace: 'nowrap' }}
      >
        Copy Link
      </Button>
    </div>
  </Alert>
</Snackbar>



  </div>
      <div style={{ color: Fontcolor, position: 'absolute', bottom: 0, left: 0, fontSize: '18px', margin: 60, zIndex: 10 }}>
        {Name}
        <br />
        
        

        <div href={URL2} style={{ position: 'relative', marginTop: 10, display: 'inline-block' }}>
  <a style={{ color: Fontcolor, fontSize: '15px', fontWeight: 600, letterSpacing: 2, textDecoration: 'none' }} href={URL2}>
    {Linkcopy}
  </a>

          <div style={{ marginTop: 6, height: 2.5, width: '100%', background: Fontcolor }} />
        </div>
        <br />
      </div>
      <Leva  hidden={!isLoggedIn} />
      <VRButton
  className="vr-button"
  style={{
    zIndex: 10000,
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    width: '60px',
    height: '60px',
    backgroundColor: 'white',
    color: 'black',
    borderRadius: '50%',
    border: 'none',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    fontFamily: 'Archivo Black',
    fontSize: '1.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'all 0.2s ease-in-out'
  }}
  onMouseEnter={(e) => { e.target.style.backgroundColor = '#f0f0f0'; }}
  onMouseLeave={(e) => { e.target.style.backgroundColor = 'white'; }}
  onMouseDown={(e) => { e.target.style.boxShadow = '0px 0px 2px rgba(0, 0, 0, 0.5)'; }}
  onMouseUp={(e) => { e.target.style.boxShadow = '0px 2px 4px rgba(0, 0, 0, 0.5)'; }}
>
  VR
</VRButton>
      <Canvas class="canvas" shadows camera={{ position: [0, 1.5, 10], fov: 25 }} style={{ background: BackgroundRef.current }}>
        <XR>
          <Controllers/>
          <group scale={1} position={[0, 0, 0]}></group>
    
      
          
          <group>
          
        
          </group>
<Lights position={[0,-0.1,0]}/>
      <Vologram1 url={Price} />
      <ContactShadows resolution={2048} blur={0.2} opacity={0.25} />
          <OrbitControls autoRotate={Rotate} autoRotateSpeed={0.2} minPolarAngle={0} maxPolarAngle={Math.PI / 2} maxDistance={15} target={[0, 1, 0]} />
        
        </XR>
      </Canvas>
    </>
  )
}

function Base(props) {
  const { Background } = useControls({
    Background: { value: 'goldenrod', label: 'Background' }
  })
  const { scene, materials } = useGLTF('/cyl3.glb')
  useLayoutEffect(() => {
    scene.traverse((obj) => obj.isMesh && (obj.receiveShadow = obj.castShadow = true))
    applyProps(materials.chicken, {
      color: Background,
      roughness: 1,
      normalMap: new THREE.CanvasTexture(new FlakesTexture(), THREE.UVMapping, THREE.RepeatWrapping, THREE.RepeatWrapping),
      'normalMap-repeat': [10, 10],
      normalScale: [0.05, 0.05]
    })
  })
  return <primitive object={scene} {...props} />
}

function Wall(props) {
  const { Background } = useControls({
    Background: { value: 'goldenrod', label: 'Background' }
  })
  const { scene } = useGLTF('https://spestect.sirv.com/Torus.glb')
  useLayoutEffect(() => {
    scene.traverse((obj) => obj.isMesh && (obj.receiveShadow = obj.castShadow = true))
  })
  return <primitive object={scene} {...props} rotation={[0, Math.PI / 2, 0]} position={[0, 0.15, 0]} />
}

function Torus() {
  const { Background } = useControls({
    Background: { value: 'goldenrod', label: 'Background' }
  })
  const ref = useRef()
  useFrame((state, delta) => (ref.current.rotation.x = ref.current.rotation.y += delta))
  return (
    <TorusKnot ref={ref} scale={[1.5, 1.5, 1.5]} castShadow receiveShadow args={[0.1, 0.03, 100, 15]} position={[0, 1, 0]}>
      <meshStandardMaterial color={Background} toneMapped={false} />
    </TorusKnot>
  )
}

function Lights(props) {

  const { scene, materials } = useGLTF('https://spestect.sirv.com/seat2.glb')

  return <primitive object={scene} {...props} />
}

function Model4({ model, ...props }) {
  const { scene, animations } = model
  const mixer = useRef(null)

  useEffect(() => {
    scene.traverse((obj) => obj.isMesh && (obj.receiveShadow = obj.castShadow = true))

    if (animations && animations.length > 0) {
      mixer.current = new THREE.AnimationMixer(scene)
      animations.forEach((clip) => mixer.current.clipAction(clip).play())
    }

    return () => {
      if (mixer.current) mixer.current.stopAllAction()
    }
  }, [scene, animations])

  useFrame((state, delta) => {
    if (mixer.current) {
      mixer.current.update(delta * 1) // <-- set speed here
    }
  })
  return <primitive object={scene} castShadow position={[0, 1, 0]} scale={1} {...props} />
}

function Vologram1({ url, props }) {
  const [scene] = useState(() => new THREE.Scene())
  useEffect(() => {
    let vologram = new Vologram(url)
    scene.add(vologram)

    return () => {
      scene.remove(vologram)
    }
  }, [url])
  return (
    <group>
      <primitive position={[0,-0.03,0]} object={scene} /> 
    </group>
  )
}
function Env() {
  const [preset, setPreset] = useState('sunset')
  // You can use the "inTransition" boolean to react to the loading in-between state,
  // For instance by showing a message
  const [inTransition, startTransition] = useTransition()
  const { blur } = useControls({
    blur: { value: 0.65, min: 0, max: 1 },
    Background2: {
      value: preset,
      options: ['sunset', 'dawn', 'night', 'warehouse', 'forest', 'apartment', 'studio', 'city', 'park', 'lobby'],
      // If onChange is present the value will not be reactive, see https://github.com/pmndrs/leva/blob/main/docs/advanced/controlled-inputs.md#onchange
      // Instead we transition the preset value, which will prevents the suspense bound from triggering its fallback
      // That way we can hang onto the current environment until the new one has finished loading ...
      onChange: (value) => startTransition(() => setPreset(value))
    }
  })
  return <Environment preset={preset} background blur={blur} />
}