const token = localStorage.getItem('jwtToken');
let email = '';

if (token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  const decodedToken = JSON.parse(atob(base64));
  email = decodedToken.Email;
}

const account = {
  displayName: email,
  email: email,
  photoURL: '/assets/images/avatars/PP.png',
};

export default account;
